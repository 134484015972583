import React, { Fragment } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useHistory, Link } from 'react-router-dom';

import { H1, H2, H3, H4, LText, MText, Spacer, Divider, AccentButton } from '@lafabrica/core';
import { OpeningVideoPanel, FeaturesPanel, Panel } from '@lafabrica/landing';

import { AvoyFooter } from '../../components/footer/AvoyFooter';

const avoyFeaturesBg = `url('https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/bg.png')`;

const width = window.innerWidth;

const BlackWrapper = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--black);
  color: var(--white);
  width: fit-content;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--black);
  opacity: 0.4;
`;

export const NewLanding = () => {
  const history = useHistory();

  return (
    <Fragment>
      <OpeningVideoPanel
        includeBanner={true}
        transparentHeader={true}
        overlay={true}
        videoUrl={'https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/avoy-landing.mp4'}
        photoUrl={'https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/avoy-landing-first-frame.jpeg'}
        backupImg={
          'https://images.unsplash.com/photo-1465401180489-ceb5a34d8a63?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2'
        }
        textChildren={
          <Fragment>
            <H1 style={{ color: 'var(--white)', marginBottom: 10 }}>
              Discover
              <br />
              somewhere unique
            </H1>
            <LText style={{ color: 'var(--white)' }}>
              Be the first to uncover the best hidden travel destinations that the world has to offer.
            </LText>
            <Spacer times={2} />
            <AccentButton style={{ width: 220, minWidth: 'auto' }} onClick={() => history.push(`/membership`)}>
              BECOME AN AVOYAGER
            </AccentButton>
          </Fragment>
        }
      />
      <Panel
        style={{ backgroundColor: 'var(--white)', minHeight: 440, alignItems: 'flex-start' }}
        leftStyle={
          width < 769
            ? { flexDirection: 'column', alignItems: 'center', width: '100%', height: 'auto', paddingTop: 40 }
            : { flexDirection: 'column', alignItems: 'center', width: 'auto', padding: '0 120px', paddingTop: 60 }
        }
        leftSide={
          width < 769 ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <H2>Our Mission</H2>
              <Divider width={100} height={1} color={'var(--secondary)'} solid />
            </div>
          ) : (
            <div>
              <Spacer />
              <H2>Our Mission</H2>
              <Divider width={100} height={1} color={'var(--secondary)'} solid />
            </div>
          )
        }
        rightStyle={
          width < 769
            ? { flexDirection: 'column', alignItems: 'center' }
            : { minHeight: 440, flexDirection: 'column', alignItems: 'center' }
        }
        rightSide={
          <div style={width < 769 ? { padding: 20, textAlign: 'center' } : { padding: 20 }}>
            <H4>
              We believe the most memorable travel experiences come when we allow ourselves to wander off the beaten
              path.
              <Spacer />
              Our goal is to show you unique destinations in the world and truly authentic travel content you won’t find
              anywhere else.
              <Spacer />
              Here’s to <u>discovering somewhere unique.</u>
            </H4>
          </div>
        }
      />
      <FeaturesPanel
        textColor={'var(--white)'}
        subTitleColor={'var(--secondary)'}
        items={[
          {
            title: (
              <Fragment>
                Wander off the
                <br />
                beaten path
              </Fragment>
            ),
            subTitleA: 'HIDDEN GEMS DELIVERED',
            subTitleB: 'A TRIP WITHIN YOUR TRIP',
            subTextA:
              'We help you take the road less traveled by sending you destinations based off your travel history, preferences and goals.',
            subTextB:
              'Got existing travel plans? Discover hidden gems nearby your destination for a truly authentic travel experience.',
          },
          {
            title: (
              <Fragment>
                White-glove
                <br />
                service
              </Fragment>
            ),
            subTitleA: 'INSPIRATION DELIVERED',
            subTitleB: 'PERSONALIZED FOR YOUR GOALS',
            subTextA:
              'Delivered via text messages or in-app notifications, your personal concierge identifies deals, insightful stories, and trip ideas based on your interests and bucketlist.',
            subTextB:
              'Want to visit 30 countries by 30? Always wanted to go on a Safari? We will be working for you to make those goals a reality.',
          },
          {
            title: (
              <Fragment>
                Be the first
                <br />
                to know
              </Fragment>
            ),
            subTitleA: 'A COMMUNITY OF EXPLORERS',
            subTitleB: 'FOLLOW OTHER TRAVEL ENTHUSIASTS',
            subTextA:
              'Our tight-knit community of travellers are always on the lookout for the next hidden gem. Stay ahead of the curve and avoid crowds.',
            subTextB: 'See where your friends and other top travellers are thinking of heading next.',
          },
        ]}
        images={[
          'https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/avoy-landing-page-explore.gif',
          'https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/landing/notification.gif',
          'https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/avoy-landing-page-passport.gif',
        ]}
        backgroundImage={avoyFeaturesBg}
        dark={true}
      />
      <Panel
        style={{
          background: `var(--light-black)
            url('https://images.unsplash.com/photo-1470181002772-82478d164015?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2')
            no-repeat center center / cover`,
          minHeight: 640,
          alignItems: 'flex-start',
          backgroundBlendMode: 'saturation',
        }}
        leftStyle={
          width < 769
            ? { minHeight: 640, alignItems: 'center' }
            : { minHeight: 640, alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 40 }
        }
        leftSide={
          <div
            style={
              width < 769
                ? { display: 'flex', flexDirection: 'column', alignItems: 'center' }
                : { position: 'relative' }
            }
          >
            <BlackWrapper>
              <H2 style={{ margin: 0 }}>Travel Done Right</H2>
            </BlackWrapper>
            <Spacer times={2} />
            <BlackWrapper>
              <LText>
                <strong>No</strong> clickbait
              </LText>
            </BlackWrapper>
            <Spacer times={0.7} />
            <BlackWrapper>
              <LText>
                <strong>No</strong> "top 10 lists"
              </LText>
            </BlackWrapper>
            <Spacer times={0.7} />
            <BlackWrapper>
              <LText>
                <strong>No</strong> bland recommendations
              </LText>
            </BlackWrapper>
            <Spacer times={0.7} />
            <BlackWrapper>
              <LText>
                <strong>True exploration</strong>
              </LText>
            </BlackWrapper>
          </div>
        }
      />
      <AvoyFooter />
    </Fragment>
  );
};
