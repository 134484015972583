import React from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';

export const HackerNews = () => {
  if (isMobile) {
    return <Redirect to="/app" />;
  }

  return <Redirect to="/" />;
};
