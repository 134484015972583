export function getPlaceAction(slug) {
  return {
    url: `/v1/public/places/place/${slug}/`,
    method: 'get',
  };
}

export function getNotificationAction(uuid) {
  return {
    url: `/v1/public/notifications/notification/${uuid}/`,
    method: 'get',
  };
}

export function getPassportAction(displayName) {
  return {
    url: `/v1/public/users/passport/${displayName}/`,
    method: 'get',
  };
}

export function resetPasswordAction(email) {
  return {
    url: `/v1/public/users/reset_password/`,
    data: { email },
    method: 'post',
  };
}

export function registerWaitlistAction(data) {
  return {
    url: `/v1/public/users/waitlist/`,
    data: data,
    method: 'post',
  };
}

export function inviteWaitlistAction(data) {
  return {
    url: `/v1/public/users/waitlist_invite/`,
    data: data,
    method: 'post',
  };
}

export function updatePasswordAction(uuid, pw) {
  return {
    url: `/v1/public/users/reset_password/${uuid}/`,
    data: { pw },
    method: 'post',
  };
}

export function conciergeAction(data) {
  return {
    url: `/v1/public/users/concierge/`,
    data: data,
    method: 'post',
  };
}

export function getPublicPressKitAction(slug) {
  return {
    url: `/v1/public/notifications/partner/presskit/public/${slug}/`,
    method: 'get',
  };
}
