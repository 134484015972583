import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 5px;
`;

const Text = styled.span`
  color: var(--white);
  font-weight: 700;
  font-size: 11px;
`;

export const UnsplashCredits = ({ name, username }) => {
  return (
    <Wrapper>
      <Text>{name} / Unsplash</Text>
    </Wrapper>
  );
};
