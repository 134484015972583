import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { getPublicPressKitAction } from '../../api/api-actions';
import { useApi } from '../../api/use-api';
import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { FlagIcon } from '../../components/FlagIcon';
import { Photo } from '../../components/Photo';

import { H2, H3, H4, Spacer, LText, MText, SText, SmallHeader, AccentButton } from '@lafabrica/core';

import { useWindowDimensions } from '../../utils/use-window';

const Head = styled.div`
  width: 100%;
  border-radius: 50px;
  background-color: var(--white);
  margin: auto;
  margin-top: -150px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 600px;
    border-radius: 100px;
  }
`;

const InnerContainer = styled.div`
  padding: 40px;
`;

const CountryTitle = styled(LText)`
  text-transform: uppercase;
  color: var(--light-black);
  margin-left: 8px;
`;

const Row = styled.div`
  display: inline-flex;
  align-items: center;
`;

const WidthManager = styled.div`
  max-width: calc(100vw - 80px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    width: 520px;
  }
`;

const ImageBackground = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.8;
`;

export const PublicPressKitScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const paths = location.pathname.split('/');
  const [{ data, loading, error }] = useApi(getPublicPressKitAction(paths[2]));

  if (loading) {
    return null;
  }

  if (error) {
    // show not found!
    history.push('/');
    return null;
  }

  const Wrapper = styled.div`
    text-align: center;
    height: 90vh;
    width: 100vw;
    background: var(--light-black) url(${data.cover_photo.url}) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  `;

  const Title = styled.span`
    font-family: 'Domine', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: ${Math.min(70, width / (data.title.length * 0.87))}px;
    color: var(--black);

    @media only screen and (min-width: 768px) {
      font-size: ${Math.min(120, 520 / (data.title.length * 0.87))}px;
    }
  `;

  function downloadImages() {
    data.images.forEach(async item => {
      let a = await document.createElement('a');
      await a.setAttribute('href', item.url);
      await a.setAttribute('download', '');
      await a.setAttribute('target', '_blank');
      await a.click();
    });
  }

  return (
    <Fragment>
      <Wrapper />
      <Head>
        <InnerContainer>
          <Spacer times={0.25} />
          <Title>{data.title}</Title>
          <Spacer />
          <WidthManager>
            <Spacer />
            <AccentButton onClick={downloadImages}>
              <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
              Download {data.images.length} Image(s)
            </AccentButton>
            <Spacer times={0.25} />
            <SText>Make sure your ad-blocker isn't blocking any pop-ups</SText>
            <Spacer />
            {data.blocks &&
              data.blocks.length > 0 &&
              data.blocks.map((item, idx) => {
                return (
                  <Fragment>
                    {item.data.type === 'image' ? (
                      <ImageBackground
                        src={item.data.manual ? item.data.url : `${item.data.url}&h=800&dpi=2`}
                        style={{ position: 'relative' }}
                      />
                    ) : item.data.type === 'copy' ? (
                      <Fragment>
                        {item.data.title && <H4>{item.data.title}</H4>}
                        <LText style={{ textAlign: 'center' }}>{item.data.description}</LText>
                      </Fragment>
                    ) : (
                      <H3>{item.data.title}</H3>
                    )}
                    <Spacer times={2} />
                  </Fragment>
                );
              })}
          </WidthManager>
        </InnerContainer>
      </Head>

      <AvoyFooter />
    </Fragment>
  );
};
