import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Divider, AccentBorderButton, LText } from '@avoy/core';

import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { CenterHeader } from '../../components/CenterHeader';
import { TextField } from '../../components/TextField';
import { resetPassword, updatePassword } from '../../api/api-requests';

const Wrapper = styled.div`
  text-align: center;
  height: 90vh;
  width: 100vw;
  background: var(--light-black)
    url('https://images.unsplash.com/photo-1527732030272-b5ad3ec7651a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2')
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px auto;
  max-width: 600px;
  padding: 20px;
  text-align: center;
`;

export const ForgotPasswordScreen = () => {
  const [value, setValue] = useState(undefined);
  const [submitted, setSubmitted] = useState(false);
  const params = useParams();

  function handleSubmit() {
    resetPassword(value);
    setInterval(() => {
      setSubmitted(true);
    }, 150);
  }

  function submitNewPassword() {
    updatePassword(params.uuid, value);
    setInterval(() => {
      setSubmitted(true);
    }, 150);
  }

  function renderPasswordPrompt() {
    if (!submitted) {
      return (
        <Fragment>
          <TextField label="Password" name="password" type="password" value={value} setValue={setValue} />
          <Divider height={10} />
          <AccentBorderButton onClick={submitNewPassword}>Reset Password</AccentBorderButton>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <h5>Password Updated!</h5>
        <LText>You can now login to the Avoy app.</LText>
      </Fragment>
    );
  }

  function renderEmailPrompt() {
    if (!submitted) {
      return (
        <Fragment>
          <TextField label="Email" name="email" value={value} setValue={setValue} />
          <Divider height={10} />
          <AccentBorderButton onClick={handleSubmit}>Reset Password</AccentBorderButton>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <h5>Thank you</h5>
        <LText>
          If there is an account associated with that account you will receive a link to reset your password.
        </LText>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Wrapper>
        <Divider height={60} />
        <CenterHeader title="Reset Your Password" />
      </Wrapper>
      <Content>{params.uuid ? renderPasswordPrompt() : renderEmailPrompt()}</Content>
      <AvoyFooter />
    </Fragment>
  );
};
