import React from 'react';
import styled from 'styled-components';
import ReactCountryFlag from 'react-country-flag';

import { SmallHeader } from '@avoy/core';

const Wrapper = styled.div`
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid var(--light-grey);
  margin: 5px;
`;

export const PlaceBadge = ({ iso_code_2, title, country = true, places = [] }) => {
  return (
    <Wrapper>
      <SmallHeader>
        <ReactCountryFlag
          countryCode={iso_code_2.toUpperCase()}
          svg
          style={{
            marginRight: '5px',
            marginBottom: '1.6px',
          }}
          aria-label={title}
        />
        {title}
        {places.length > 0 && ` (${places.length})`}
      </SmallHeader>
    </Wrapper>
  );
};
