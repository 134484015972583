import React, { Fragment } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedin, faMedium } from '@fortawesome/free-brands-svg-icons';
import { useHistory, Link } from 'react-router-dom';

import { LText, MText, Divider } from '@avoy/core';
import { Footer } from '@lafabrica/landing';

import purpleLogo from '../../assets/square-logo-100-purple.svg';

const width = window.innerWidth;

const DropHighlight = styled.div`
  position: absolute;
  top: 70%;
  background-color: var(--accent);
  width: -webkit-fill-available;
  height: 40%;
  opacity: 0.3;
`;

const LogoSquare = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 5px;
`;

const HeaderLogo = styled.span`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-decoration: none;
`;

const FooterLink = styled.a`
  cursor: pointer;
  color: var(--black);
  margin: 0 5px;

  &:hover {
    color: var(--accent);
  }
`;

export const AvoyFooter = () => {
  const history = useHistory();
  return (
    <Footer
      leftSide={
        <Fragment>
          <div
            style={
              width < 769
                ? { display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }
                : { display: 'flex', alignItems: 'center', cursor: 'pointer' }
            }
            onClick={() => history.push(`/`)}
          >
            <LogoSquare src={purpleLogo} />
            <HeaderLogo>Avoy</HeaderLogo>
          </div>
          <Divider height={20} />
          <div
            style={
              width < 769
                ? { position: 'relative', width: 'fit-content', margin: 'auto' }
                : { position: 'relative', width: 'fit-content' }
            }
          >
            <LText>Discover somewhere unique</LText>
            <DropHighlight />
          </div>
          <Divider height={20} />
          <FooterLink href="https://www.instagram.com/avoytravel" target="_blank" noopener noreferrer>
            <FontAwesomeIcon icon={faInstagram} size={'lg'} />
          </FooterLink>
          <FooterLink href="https://www.linkedin.com/company/avoy/" target="_blank" noopener noreferrer>
            <FontAwesomeIcon icon={faLinkedin} size={'lg'} />
          </FooterLink>
          <FooterLink href="https://www.medium.com/avoy/" target="_blank" noopener noreferrer>
            <FontAwesomeIcon icon={faMedium} size={'lg'} />
          </FooterLink>
          <Divider height={width < 769 ? 40 : 100} />
          <MText>
            Made with&nbsp;
            <FontAwesomeIcon icon={faHeart} color={'var(--red)'} />
            &nbsp; from{' '}
            <strong>
              <u>around the world</u>
            </strong>
          </MText>
        </Fragment>
      }
      rightSideItems={[
        {
          header: 'Work With Us',
          items: [
            { name: 'Destinations', onClick: 'https://partners.avoy.io/?utm_source=avoy', external: true },
            { name: 'Attractions', onClick: 'https://partners.avoy.io/?utm_source=avoy', external: true },
            { name: 'Creators', onClick: 'https://partners.avoy.io/influence?utm_source=avoy', external: true },
          ],
        },
        {
          header: 'Company',
          items: [
            { name: 'Press', onClick: () => history.push(`/press`) },
            { name: 'Privacy Policy', onClick: 'https://www.iubenda.com/privacy-policy/26986804', external: true },
            {
              name: 'Terms & Conditions',
              onClick: 'https://www.termsfeed.com/terms-conditions/573f198132a2dceea013fa02a04a825e',
              external: true,
            },
            { name: 'hi@avoy.io', onClick: 'mailto:hi@avoy.io', external: true },
          ],
        },
      ]}
    />
  );
};
