import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons';

import { H1, H2, H3, H4, LText, MText, SText, Spacer, Divider, AccentButton } from '@lafabrica/core';
import { OpeningPanel } from '@lafabrica/landing';

import { conciergeRegister } from '../../api/api-requests';
import { TextField } from '../../components/TextField';
import { AvoyFooter } from '../../components/footer/AvoyFooter';

const width = window.innerWidth;

const A = styled.a`
  color: var(--accent);
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;

  &:visited {
    color: var(--accent);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const Concierge = () => {
  const history = useHistory();
  const [avoyAccount, setAvoyAccount] = useState(false);
  const [value, setValue] = useState(undefined);
  const [submitted, setSubmitted] = useState(false);

  function toggleAccount() {
    setAvoyAccount(!avoyAccount);
    setValue(undefined);
  }

  function onSubmit() {
    setSubmitted(true);
    conciergeRegister({ value });
  }

  function validResponse() {
    if (avoyAccount) {
      if (value && value.length > 0) {
        if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
          return false;
        }
      } else {
        return false;
      }
    } else {
      if (value && value.length > 6 && value.length < 13) {
        const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if (!value.match(reg)) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  return (
    <Fragment>
      <OpeningPanel
        backgroundImage={
          'https://images.unsplash.com/photo-1571692714879-48c5c6f76831?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=1024&dpi=2'
        }
        overlay={true}
        textChildren={
          <Fragment>
            <LText>
              <FontAwesomeIcon icon={faConciergeBell} size={12} style={{ marginRight: 6 }} color={'var(--accent)'} />
              <strong>Concierge</strong>
            </LText>
            <SText style={{ fontWeight: 'bold', marginLeft: 6 }}>by Avoy</SText>
            <H2 style={{ marginTop: 18, marginBottom: 14 }}>
              Curated <span style={{ color: 'var(--accent)' }}>inspiration</span>
              <br />
              delivered by text
            </H2>
            <LText>
              Authentic travel{' '}
              <A
                href="https://www.alongdustyroads.com/posts/bordeaux-mayor-juppe-transformation?ref=avoy"
                target="_blank"
                rel="noreferrer noredirect"
              >
                stories
              </A>
              , and <A onClick={() => history.push(`/trip/1`)}>itineraries</A> curated just for you and sent via text
              once a week.
            </LText>
            <Spacer times={1.5} />
            {submitted ? (
              <MText>
                <strong>Submitted!</strong>
                <br />
                Expect a{avoyAccount ? ' email' : ' text message'} from your friends at Avoy to get setup with your Avoy
                Concierge.
              </MText>
            ) : (
              <Fragment>
                <Row>
                  <TextField
                    label={avoyAccount ? 'Avoy username' : 'phone'}
                    placeholder={avoyAccount ? 'traveller@avoy.io' : '+44 7911 123456'}
                    name={'value'}
                    value={value}
                    setValue={setValue}
                  />
                  <AccentButton
                    style={
                      width < 769
                        ? { width: 180, minWidth: 'auto', margin: 'auto', marginTop: 10 }
                        : { width: 160, minWidth: 'auto', marginLeft: 20 }
                    }
                    onClick={onSubmit}
                    disabled={!validResponse()}
                  >
                    GET STARTED
                  </AccentButton>
                </Row>
                <Spacer times={0.5} />
                <SText onClick={toggleAccount}>
                  <A>{avoyAccount ? "I don't have an Avoy account" : 'I already have an Avoy account'}</A>
                </SText>
              </Fragment>
            )}
            <Spacer />
          </Fragment>
        }
      />
      <AvoyFooter />
    </Fragment>
  );
};
