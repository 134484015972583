import React from 'react';
import styled from 'styled-components';

import { UnsplashCredits } from './UnsplashCredits';

export const Photo = ({ url, name, username, width = '100%' }) => {
  const Wrapper = styled.div`
    width: ${width};
    overflow: hidden;
    border-radius: 30px;
    position: relative;
    background-color: var(--light-black);
  `;

  return (
    <Wrapper>
      <a href={`https://unsplash.com/${username}`} target="_blank" rel="noopener noreferrer">
        <img src={url} alt="" style={{ maxWidth: '100%' }} />
      </a>
      <UnsplashCredits name={name} username={username} />
    </Wrapper>
  );
};
