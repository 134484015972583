import React from 'react';
import styled from 'styled-components';

import { Divider } from '@avoy/core';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
`;

export const CenterHeader = ({ title }) => (
  <Container>
    <h2 style={{ marginBottom: 20, color: 'var(--white)' }}>{title}</h2>
    <Divider height={20} />
    <Divider height={2} color={'var(--secondary)'} width={100} solid />
  </Container>
);
