// COPIED FROM NATIVE
export function removeDuplicates(pins) {
  const DUPLICATE_PRIORITY = ['TARGET', 'CURRENT_CITY', 'SAVE', 'HUB', 'NEARBY_DESTINATION', 'NEARBY', 'VISIT'];

  if (!pins) {
    return [];
  }

  const ids = new Set(pins.map(pin => pin.uuid));
  const newPins = [...ids].map(id => {
    if (pins.map(pin => pin.uuid).indexOf(id) > 1) {
      let satisfied = null;
      for (let i = 0; i < DUPLICATE_PRIORITY.length; i++) {
        if (!satisfied) {
          if (pins.filter(pin => pin.uuid === id && pin.pin_type === DUPLICATE_PRIORITY[i]).length > 0) {
            satisfied = i;
          }
        }
      }
      return pins.filter(pin => pin.uuid === id && pin.pin_type === DUPLICATE_PRIORITY[satisfied])[0];
    } else {
      return pins.filter(pin => pin.uuid === id)[0];
    }
  });

  return newPins;
}
