import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';

import { AxiosHookProvider } from './api/axios-hook';
import { WrappedMainRouter } from './router/MainRouter';
import { axiosApi } from './api/http';

const OuterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

function App() {
  return (
    <OuterWrapper id="outer-container">
      <AxiosHookProvider axios={axiosApi}>
        <Router>
          <WrappedMainRouter />
        </Router>
      </AxiosHookProvider>
    </OuterWrapper>
  );
}

export default App;
