import React from 'react';
import styled from 'styled-components';

import { UnsplashCredits } from './UnsplashCredits';

export const DestinationPhoto = ({ url, name, username }) => {
  const Wrapper = styled.div`
    width: 240px;
    height: 180px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    background-color: var(--light-black);
  `;

  return (
    <Wrapper>
      <img src={url} alt="" style={{ minWidth: 240, height: 180 }} />
      <UnsplashCredits name={name} username={username} />
    </Wrapper>
  );
};
