import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { SmallHeader, Divider } from '@avoy/core';

const Wrapper = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Input = styled.input`
  min-width: 250px;
  height: 30px;
  font-size: 16px;
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid var(--accent);
  ${props => props.dark && `background: none; color: var(--white);`}

  &:focus {
    outline: none;
    border-bottom: 1px solid var(--secondary);
  }
`;

export const TextField = ({ label, name, value, setValue, dark = false, placeholder = null, ...props }) => {
  const [pHolder, setPHolder] = useState(placeholder);

  useEffect(() => {
    setPHolder(placeholder);
  }, [placeholder]);

  function handleOnChange(obj) {
    setValue(obj.target.value);
  }

  function onFocus() {
    if (pHolder) {
      setPHolder(null);
    }
  }

  return (
    <Wrapper>
      <SmallHeader style={dark ? { color: 'var(--white)' } : {}}>{label}</SmallHeader>
      <Divider height={5} />
      <Input
        type="text"
        name={name}
        value={value}
        onChange={handleOnChange}
        onFocus={onFocus}
        placeholder={pHolder}
        {...props}
        dark={dark}
      />
    </Wrapper>
  );
};
