import React, { useState, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons';

import { H3, LText, MText, WhiteButton } from '@avoy/core';
import { Header, NavLink } from '@lafabrica/landing';
import { Spacer } from '@lafabrica/core';
import { Logo } from './Logo';
import { TextMeTheApp } from './TextMeTheApp';

const VerticalLine = styled.div`
  width: 1px;
  height: 20px;
  background-color: var(--white);
  margin: 0 20px;
`;

const NavItem = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 20px;
  width: 100%;
  color: var(--white);
`;

export const NewHeader = ({ transparent, showBanner }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  // TO DO - add in
  // <NavLink color={'var(--white)'} onClick={() => setShowModal(true)}>Text me the App</NavLink>
  return (
    <Fragment>
      <Header
        includeBanner={showBanner}
        transparent={transparent}
        mobileContent={
          <Fragment>
            <Spacer times={0.5} />
            <NavItem onClick={() => history.push(`/app`)}>Get the App</NavItem>
            <NavItem onClick={() => history.push(`/membership`)}>
              <strong style={{ color: 'var(--secondary)' }}>Join Avoy</strong>
            </NavItem>
          </Fragment>
        }
        logo={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Logo />
          </div>
        }
        headerBackgroundColor={'var(--black)'}
        boxShadow={true}
        bannerOnClick={() => history.push(`/app`)}
        bannerChildren={
          isMobile ? (
            <MText style={{ color: 'var(--white)' }}>Check out our brand new app!</MText>
          ) : (
            <Fragment>
              <LText style={{ color: 'var(--white)', fontWeight: 'bold' }}>Hey over there!</LText>
              <VerticalLine />
              <LText style={{ color: 'var(--white)' }}>
                We just released a new app on iOS & Android - click here to check it out!
              </LText>
            </Fragment>
          )
        }
        logo={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Logo />
          </div>
        }
        nav={
          <Fragment>
            <NavLink color={'var(--white)'} onClick={() => history.push(`/app`)}>
              Get the App
            </NavLink>
            <NavLink color={'var(--secondary)'} onClick={() => history.push(`/membership`)}>
              Join Avoy
            </NavLink>
          </Fragment>
        }
      />
      <TextMeTheApp visible={showModal} close={() => setShowModal(false)} />
    </Fragment>
  );
};
