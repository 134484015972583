import React, { useEffect, useState } from 'react';

const AxiosContext = React.createContext();

export function AxiosHookProvider({ children, axios }) {
  const [cache, setCache] = useState({});
  const contextValue = {
    cache,
    setCache,
    axios,
  };

  return <AxiosContext.Provider value={contextValue}>{children}</AxiosContext.Provider>;
}

function getCacheKey(action, { transform }) {
  return JSON.stringify({ ...action, transform: transform.toString() });
}

function shouldFetch(cache, cacheKey) {
  const cacheValue = cache[cacheKey];
  if (!cacheValue) {
    return true;
  }

  return cacheValue.invalidated;
}

const defaultOpts = {
  transform: x => x,
};

export function useAxios(axiosAction, opts = defaultOpts) {
  const { cache, setCache, axios } = React.useContext(AxiosContext);
  const cacheKey = getCacheKey(axiosAction, opts);
  const { transform } = opts;

  function refetch(data, error, invalidated = true) {
    setCache(cache => ({
      ...cache,
      [cacheKey]: {
        ...cache[cacheKey],
        data: data || cache[cacheKey].data,
        error: error || cache[cacheKey].error,
        invalidated,
      },
    }));
  }

  function setApiState(data, error) {
    refetch(data, error, false);
  }

  const initialRequestState = {
    loading: true,
    data: undefined,
    error: undefined,
    invalidated: false,
    refetch,
    setApiState,
  };

  useEffect(() => {
    if (!shouldFetch(cache, cacheKey)) {
      return;
    }

    async function doAxiosRequest() {
      setCache(cache => ({
        ...cache,
        [cacheKey]: { ...initialRequestState, ...cache[cacheKey], loading: true, invalidated: false },
      }));

      let data = undefined;
      let error = undefined;
      try {
        data = await transform((await axios(axiosAction)).data);
      } catch (e) {
        error = e;
      }
      setCache({
        ...cache,
        [cacheKey]: {
          refetch,
          data,
          error,
          loading: false,
          setApiState,
        },
      });
    }

    doAxiosRequest();
  });

  return cache[cacheKey] || initialRequestState;
}
