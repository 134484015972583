import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Divider, SText, MText, LText } from '@avoy/core';

import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { CenterHeader } from '../../components/CenterHeader';

const Wrapper = styled.div`
  text-align: center;
  height: 90vh;
  width: 100vw;
  background: var(--light-black)
    url('https://images.unsplash.com/photo-1515627043029-d5fcda8b150d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2')
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 80px auto;
  max-width: 600px;
  padding: 20px;
  text-align: left;
`;

const A = styled.a`
  text-decoration: none;
  color: var(--accent);
`;

export const ContentPolicy = () => (
  <Fragment>
    <Wrapper>
      <Divider height={60} />
      <CenterHeader title="Content Policy" />
    </Wrapper>
    <Content>
      <h5>Content Policy</h5>
      <Divider height={1} color={'var(--secondary)'} width={100} solid />
      <Divider height={30} />
      <SText>Last updated: February 11, 2020</SText>
      <Divider height={20} />
      <MText>
        Avoy regards all travel related websites that periodically publish information as a potential source. In
        choosing sources to include for our content aggregation services Avoy does apply rigorous editorial standards.
        <Divider height={15} />
        Avoy will periodically reviews sources for inclusion on our application as a result of an external request or an
        identified need for our users.
        <Divider height={15} />
        For inclusion in our Partner Program, please reach out to&nbsp;
        <A href="mailto:hi@avoy.io">hi@avoy.io</A>
        .
        <Divider height={15} />
        Avoy reserves the right to decline to add a source at its sole discretion and maintains the right to change this
        Content Policy at any time without notice.
      </MText>
      <Divider height={40} />
      <h5>Editorial Standards and Code of Conduct</h5>
      <Divider height={1} color={'var(--secondary)'} width={100} solid />
      <Divider height={30} />
      <SText>Last updated: February 11, 2020</SText>
      <Divider height={20} />
      <MText>
        In order to maintain consistent high quality content for our users, Avoy employs a strict set of Editorial
        Standard and Code of Conduct for both our Partners and our external sources.
        <Divider height={20} />
        <LText>Editorial Standards</LText>
        <Divider height={20} />
        The creditability of Avoy as a reliable source of travel-related information is dependent on the content we
        produce in-house and the quality of content from our external sources and partners. Our users expect the
        inclusion of content to have an established and reliable reputation for providing useful, valid and informative
        content for travellers. Established, authoritative sources of media (including, but not limited to, traditional
        print, magazines and internationally recognized online media) will be valid publications. If a source is
        non-authoritative, Avoy will need to demonstrate the quality and credibility of the content for equal inclusion
        alongside our authoritative sources. These guidelines should help non-authoritative sources ensure they can
        provide an improved experience for their users and increase the chances of inclusion in Avoy's content network.
        <Divider height={15} />
        We understand that not all of the standards will apply equally to different mediums of media (for example
        photography, video, etc.).
        <Divider height={15} />
        <strong>I. Attribute Sources, Claims and Quotes</strong>
        <Divider height={5} />
        Attribution allows users to verify the origin and legitimacy of the factual content of your articles. When
        Quotes must always be clearly attributed to their sources.
        <Divider height={15} />
        <strong>II. Write/Produce Original & Unique Content</strong>
        <Divider height={5} />
        There is a myriad of travel-related pieces of content out there. Our users are interested in thoughtful and
        unique content that goes beyond what they might have seen a thousand times. This content should add value to our
        user base.
        <Divider height={15} />
        <strong>III. Provide information about You or Your Organization</strong>
        <Divider height={5} />
        Users should be able to find a piece of content or webpage that describes who you or your organization is and
        how they can get in contact with you.
        <Divider height={15} />
        <strong>IV. Proof-"read" and fact-check your content</strong>
        <Divider height={5} />
        You should take reasonable steps to avoid obvious mistakes in your content whether it be written or produced in
        some other format. If corrections are required you should issue appropriate corrections.
        <Divider height={20} />
        <LText>Code of Conduct</LText>
        <Divider height={20} />
        The following practices applies to members of our Partner Program and are considered grounds for a) the request
        of a written explanation, and b) implementation of potential sanctions, which may include, written warning,
        temporary suspension or permanent removal.
        <Divider height={15} />
        1. Using sectarian, racist, defamatory or libellous language; hate speech; excessive or inappropriate profanity
        or insulting language. Content must abide by the laws of any relevant jurisdiction.
        <Divider height={5} />
        2. Attempting to manipulate the way Avoy sources and displays content or other attempting to manipulate any of
        our systems in any way.
        <Divider height={5} />
        3. Producing duplicative pieces of content (for instance one article under two different URLs).
        <Divider height={5} />
        4. Displaying an excessive amount of advertising, or advertising that is unsuitable for our users.
        <Divider height={5} />
        5. Presenting articles or content that are excessively large and/or difficult to download and display.
        <Divider height={15} />
        Avoy reserves the right to change this Editorial Standards and Code of Conduct at any time without notice.
      </MText>
    </Content>
    <AvoyFooter />
  </Fragment>
);
