import React, { useState } from 'react';
import { Marker } from 'react-map-gl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faHeart } from '@fortawesome/free-solid-svg-icons';

const PinBase = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlankBase = styled(PinBase)`
  background: none;
`;

const PinGem = styled(PinBase)`
  background-color: var(--accent);
`;

const Label = styled.div`
  background-color: inherit;
  position: absolute;
  top: -30px;
  margin: auto;
  height: 14px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: var(--white);
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
`;

const PinTarget = styled.div`
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border: 1px solid var(--light-black);
  width: 40px;
  height: 40px;
  transform: rotate(-45deg);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
`;

const InnerContainer = styled.div`
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  transform: rotate(45deg);
  max-width: 150%;
  max-height: 150%;
`;

export const Pin = ({ pin }) => {
  const [showLabel, setShowLabel] = useState(false);
  const { pin_type, place, uuid } = pin;
  const { geolocation } = place;

  function renderPin() {
    if (place.destination_type === 'COUNTRY') {
      return null;
    }
    switch (pin_type) {
      case 'TARGET':
        return (
          <PinTarget>
            <InnerContainer>
              <Image src={`${pin.place.cover_photo.url}&w=30&h=30&dpr=8`} alt="" />
            </InnerContainer>
          </PinTarget>
        );
      case 'NEARBY_DESTINATION':
        return (
          <PinGem>
            <Label>{place.title}</Label>
          </PinGem>
        );
      case 'CURRENT_CITY':
        return <FontAwesomeIcon icon={faHome} color={'var(--black)'} style={{ fontSize: 8 }} />;
      case 'SAVE':
        return (
          <BlankBase onMouseEnter={() => setShowLabel(true)} onMouseLeave={() => setShowLabel(false)}>
            {showLabel && <Label style={{ backgroundColor: 'var(--red)' }}>{place.title}</Label>}
            <FontAwesomeIcon icon={faHeart} color={'var(--red)'} style={{ fontSize: 12, cursor: 'pointer' }} />
          </BlankBase>
        );
      case 'VISIT':
        return (
          <PinBase
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setShowLabel(true)}
            onMouseLeave={() => setShowLabel(false)}
          >
            {showLabel && <Label>{place.title}</Label>}
          </PinBase>
        );
      default:
        return (
          <PinBase>
            <Label>{place.title}</Label>
          </PinBase>
        );
    }
  }

  function renderOffset() {
    switch (pin_type) {
      case 'TARGET':
        return -40;
      default:
        return -6;
    }
  }

  return (
    <Marker key={uuid} longitude={geolocation.longitude} latitude={geolocation.latitude} offsetTop={renderOffset()}>
      {renderPin()}
    </Marker>
  );
};
