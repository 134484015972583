import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

import { LText, Divider, AccentButton } from '@avoy/core';

import { truncate } from '../../utils/text';
import { getAttribute } from '../../utils/attributes';

const PartnerTitle = styled(LText)`
  color: var(--light-black);
`;

const A = styled.a`
  text-decoration: none;
`;

export const YoutubeVideo = ({ data }) => {
  let account = getAttribute('YOUTUBE_ACCOUNT', data.attributes);
  let accountUrl = getAttribute('YOUTUBE_ACCOUNT_ID', data.attributes);

  return (
    <Fragment>
      <h2 style={{ textAlign: 'center' }}>{data.title}</h2>
      <A href={`https://www.youtube.com/channel/${accountUrl}`} target="_blank" rel="noopener noreferrer">
        <PartnerTitle>
          <FontAwesomeIcon icon={faYoutube} style={{ marginRight: 10 }} />
          {account}
        </PartnerTitle>
      </A>
      <PartnerTitle>{data.partner.name}</PartnerTitle>
      <Divider height={20} />
      <Divider height={2} width={100} color={'var(--secondary)'} solid />
      <Divider height={60} />
      <LText style={{ textAlign: 'justify' }}>{truncate(data.text, 100)}</LText>
      <Divider height={60} />
      <A href={data.source.url} target="_blank" rel="noopener noreferrer">
        <AccentButton>GO TO VIDEO</AccentButton>
      </A>
      <Divider height={60} />
    </Fragment>
  );
};
