import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Divider, SText, MText, LText } from '@avoy/core';

import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { CenterHeader } from '../../components/CenterHeader';

const Wrapper = styled.div`
  text-align: center;
  height: 90vh;
  width: 100vw;
  background: var(--light-black)
    url('https://images.unsplash.com/photo-1515627043029-d5fcda8b150d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2')
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 80px auto;
  max-width: 600px;
  padding: 20px;
  text-align: left;
`;

const A = styled.a`
  text-decoration: none;
  color: var(--accent);
`;

export const ReleaseNotes = () => {
  return (
    <Fragment>
      <Wrapper>
        <Divider height={60} />
        <CenterHeader title="Release Notes" />
      </Wrapper>
      <Content>
        <h4>Release Notes</h4>
        <SText>Last updated: Jul 14, 2020</SText>
        <Divider height={30} />
        <h5>Version 1.5.7</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>We've updated your passport to make viewing hidden gems a little bit easier.</li>
            <li>We added the ability to delete tags.</li>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.5.6</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>We added some enhanced explore cards, keep an eye out for any "hot" destinations!</li>
            <li>We added additional components to our destination cards.</li>
            <li>We made it easier for you to submit a hidden gem.</li>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.5.5</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>We added more destinations for you to explore!</li>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.5.4</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>We updated the ability for you to apply tags to multiple destinations at once.</li>
            <li>We added more destinations for you to explore! </li>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.5.3</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>We made a few changes to improve the quality of your Explore recommendations.</li>
            <li>We added the ability to see who is following you.</li>
            <li>We resolved some issues that caused some problems for new users.</li>
            <li>We've sorted your Escape list to highlight closer destinations.</li>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.5.2</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>
              We've added a brand new Concierge Feature to receive curated trip ideas and personalized travel stories.
            </li>
            <li>We've upgraded your wishlist again to allow for you to organize by custom tags and lists.</li>
            <li>
              We've added the ability for you to follow fellow travellers via your Passport and to keep up to date with
              some of the best travellers on Avoy.
            </li>
            <li>We've fixed some usability bugs to make your Avoy experience even better.</li>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.5.1</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.5.0</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>
              Introduced a Concierge Feature to curate and distribute personalized travel stories, trip ideas and flight
              deals.
            </li>
            <li>We've upgraded your wishlist again to allow for you to organize by custom tags and lists.</li>
            <li>
              Avoy's gone social! We've added the ability for you to follow fellow travellers via your Passport and to
              keep up to date with some of the best travellers on Avoy.
            </li>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.4.0</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>
              Added a new "Escape" feature, which allows for exploration around a geolocated point and large travel
              destinations.
            </li>
            <li>Refactored Wishlist to be organized by Country.</li>
            <li>Implemented daily curation and delivery of destinations.</li>
            <li>Implemented updated registration process.</li>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.3.3</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.3.2</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>Added progressive image loading for smoother image retrieval.</li>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.3.1</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>Fixed reported and known bugs.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.3.0</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>Updated Explore to include footer navigation.</li>
            <li>Updated Explore look and feel to mesh with other screens in the app.</li>
            <li>
              Sped up loading times on both Explore and Wishlist pages. More to come in terms of performance
              enhancements.
            </li>
            <li>We have temporarily turned off flight deals as a result of global travel restrictions.</li>
            <li>Updated the look and feel of your Passport page.</li>
            <li>
              Added a public Passport interface so you can view other traveller's passports. Access is currently
              limited.
            </li>
            <li>Updated the "known for" icons.</li>
            <li>Updated language throughout the application.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.2.1</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>Fixes around the links provided in the Deal interface.</li>
            <li>Android Crashes resolved.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.2.0</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>
              We added COVID-19 Warnings & Alerts to help travellers make educated decisions about when/where they
              travel.
            </li>
            <li>We've added the ability to submit your own hidden gem.</li>
            <li>Updated interface for viewing flight deals.</li>
            <li>Improved loading for your passport and the countries you have visited.</li>
            <li>Updated language on the registration page.</li>
            <li>Fixed bugs around the activation of the map in the destination cards.</li>
            <li>Fixed a bug around scrolling to deals too quickly.</li>
            <li>Fixed a bug for searching for destinations on your wishlist.</li>
            <li>Updated the "known for" icons.</li>
            <li>Updated loading for your passport.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.1.0</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          <ul>
            <li>
              Additional deal screen as a part of the wishlist to house deals for flights, accommodations and more.
            </li>
            <li>Lazy loading & infinite scroll implemented for feed.</li>
            <li>Added in screens to prompt feedback and ratings.</li>
            <li>Improve feedback on the passport loading screen so that the user isn't staring at a blank screen.</li>
            <li>
              The map is fixed on the explore screen until it is tapped by the user, at which point it will become fully
              interactive.
            </li>
            <li>Remove "..." from our more photos component.</li>
            <li>Click on a destination's main photo to bring up the more photos modal.</li>
            <li>Resolved the flickering app loading on iOS.</li>
            <li>Disable autocorrect when searching for destinations.</li>
            <li>Fix fatal bug when clicking on feed links before loading has completed.</li>
            <li>Improve price-quality on getting to a destination.</li>
            <li>Zoom out further on your passport map.</li>
            <li>Update the "known for" icons.</li>
            <li>Updated tutorial to reflect the current version.</li>
            <li>Added a What's New modal.</li>
            <li>Updated the icons in your passport.</li>
          </ul>
        </MText>
        <Divider height={40} />
        <h5>Version 1.0.0</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={20} />
        <MText>
          We <strong>launched</strong>!
        </MText>
      </Content>
      <AvoyFooter />
    </Fragment>
  );
};
