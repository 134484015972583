import React, { Fragment } from 'react';
import { isAndroid } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import withHelmet from '../../utils/with-helmet';

export const DownloadApp = () => {
  if (isAndroid) {
    return (
      <Fragment>
        {withHelmet('Avoy: Download the app for iOS & Android', 'https://www.avoy.io/app')}
        <Redirect to="/android-download" />
      </Fragment>
    );
  }

  return (
    <Fragment>
      {withHelmet('Avoy: Download the app for iOS & Android', 'https://www.avoy.io/app')}
      <Redirect to="/ios-download" />
    </Fragment>
  );
};

// export const DownloadApp = withHelmet(
//   props => <NakedDownloadApp {...props} />,
//   'Avoy: Download the app for iOS & Android',
//   'https://www.avoy.io/app'
// );
