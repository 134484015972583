import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Divider, SText, MText, LText } from '@avoy/core';

import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { CenterHeader } from '../../components/CenterHeader';

const Wrapper = styled.div`
  text-align: center;
  height: 90vh;
  width: 100vw;
  background: var(--light-black)
    url('https://images.unsplash.com/photo-1571692714879-48c5c6f76831?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=1024&dpi=2')
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 80px auto;
  max-width: 600px;
  padding: 20px;
  text-align: left;
`;

const A = styled.a`
  text-decoration: none;
  color: var(--accent);
`;

export const Licenses = () => {
  return (
    <Fragment>
      <Wrapper>
        <Divider height={60} />
        <CenterHeader title="Licenses" />
      </Wrapper>
      <Content>
        <h5>Android Open Source Software</h5>
        <Divider height={1} color={'var(--secondary)'} width={100} solid />
        <Divider height={30} />
        <SText>Last updated: February 11, 2020</SText>
        <Divider height={20} />
        <MText>
          <ul>
            <li>
              <A href="https://github.com/axios/axios/blob/master/LICENSE" target="_blank" rel="noopener noreferrer">
                Axios - MIT
              </A>
            </li>
            <li>
              <A href="https://date-fns.org/v1.28.5/docs/License" target="_blank" rel="noopener noreferrer">
                date-fns - MIT
              </A>
            </li>
            <li>
              <A href="https://github.com/expo/expo/blob/master/LICENSE" target="_blank" rel="noopener noreferrer">
                Expo - MIT
              </A>
            </li>
            <li>
              <A
                href="https://github.com/jaredpalmer/formik/blob/master/LICENSE"
                target="_blank"
                rel="noopener noreferrer"
              >
                Formik - MIT
              </A>
            </li>
            <li>
              <A
                href="https://github.com/moment/momentjs.com/blob/master/LICENSE"
                target="_blank"
                rel="noopener noreferrer"
              >
                MomentJS - MIT
              </A>
            </li>
            <li>
              <A href="https://github.com/facebook/react/blob/master/LICENSE" target="_blank" rel="noopener noreferrer">
                React - MIT
              </A>
            </li>
            <li>
              <A
                href="https://github.com/facebook/react-native/blob/master/LICENSE"
                target="_blank"
                rel="noopener noreferrer"
              >
                React Native - MIT
              </A>
            </li>
            <li>
              <A
                href="https://github.com/reduxjs/react-redux/blob/master/LICENSE.md"
                target="_blank"
                rel="noopener noreferrer"
              >
                Redux - MIT
              </A>
            </li>
          </ul>
        </MText>
      </Content>
      <AvoyFooter />
    </Fragment>
  );
};
