import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactMapGL, { Layer, Source } from 'react-map-gl';
import styled from 'styled-components';

import { Pin } from './Pin';

// Country level geojson data
const GEOJSON_DATA = {
  type: 'geojson',
  data: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_countries_lakes.geojson',
};

const Wrapper = styled.div`
  overflow: hidden;
`;

export const PassportMap = ({ pins = [], visitedCountries = [] }) => {
  const [viewport, setViewport] = useState({
    width: '100vw',
    height: isMobile ? 'calc(100vh - 140px)' : 'calc(100vh - 200px)',
    latitude: 40.0,
    longitude: -20.0,
    zoom: 2,
  });

  const countryLayer = {
    id: 'shadedCountry',
    type: 'fill',
    'source-layer': 'ne_10m_admin_0_countries-0bmhf6',
    filter: [
      'any',
      ...visitedCountries.map(country => {
        return ['==', 'ADM0_A3_IS', country];
      }),
    ],
  };

  return (
    <Wrapper>
      <ReactMapGL
        {...viewport}
        mapStyle={'mapbox://styles/travelwithyonder/cjuwweh1304j01fph2ysexxeq'}
        onViewportChange={setViewport}
      >
        {pins && pins.length > 0 && pins.map(pin => <Pin pin={pin} key={pin.uuid} />)}
        <Source id="shaded-countries" type="vector" url={'mapbox://travelwithyonder.6sasxur1'}>
          <Layer {...countryLayer} paint={{ 'fill-color': '#6a67ce' }} beforeId={'urban-areas'} />
        </Source>
      </ReactMapGL>
    </Wrapper>
  );
};
