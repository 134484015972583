import React, { Fragment, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { NewHeader } from '../components/header/NewHeader';
import { BenefitsHeader } from '../components/header/BenefitsHeader';
import { NewLanding } from '../screens/landing/NewLanding';
import { Press } from '../screens/landing/Press';
import { Concierge } from '../screens/landing/Concierge';
import { ContentPolicy } from '../screens/landing/ContentPolicy';
import { Licenses } from '../screens/landing/Licenses';
import { BenefitsLanding } from '../screens/benefits/BenefitsLanding';
import { PlaceScreen } from '../screens/place/PlaceScreen';
import { NotificationScreen } from '../screens/notification/NotificationScreen';
import { PassportScreen } from '../screens/passport/PassportScreen';
import { MembershipScreen } from '../screens/account/MembershipScreen';
import { ForgotPasswordScreen } from '../screens/account/ForgotPasswordScreen';
import { DownloadApp } from '../screens/app/DownloadApp';
import { ReleaseNotes } from '../screens/app/ReleaseNotes';
import { HackerNews } from '../screens/app/HackerNews';
import { Article } from '../screens/blog/Article';
import { SampleTrip } from '../screens/app/SampleTrip';
import { PublicPressKitScreen } from '../screens/presskit/PublicPressKitScreen';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';

const VerticalLine = styled.div`
  width: 1px;
  height: 20px;
  background-color: var(--white);
  margin: 0 20px;
`;

const MainRouter = props => {
  const [dark, setDark] = useState(false);
  const [banner, setBanner] = useState(false);
  const { pathname } = props.location;

  useEffect(() => {
    setBanner(pathname === '/');
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <Fragment>
      {props.location.pathname.startsWith('/benefits') ? (
        <BenefitsHeader />
      ) : (
        <NewHeader
          transparent={
            !(
              pathname.startsWith('/notification') ||
              pathname.startsWith('/app') ||
              pathname.startsWith('/passport') ||
              pathname.startsWith('/concierge') ||
              pathname.startsWith('/ios-download') ||
              pathname.startsWith('/blog') ||
              pathname.startsWith('/android-download')
            )
          }
          showBanner={banner}
        />
      )}
      <div id="main-screen">
        <Switch>
          <Route path="/membership">
            <MembershipScreen />
          </Route>
          <Route path="/hn">
            <HackerNews />
          </Route>
          <Route path="/press">
            <Press />
          </Route>
          <Route path="/content-policy">
            <ContentPolicy />
          </Route>
          <Route path="/licenses">
            <Licenses />
          </Route>
          <Route path="/release">
            <ReleaseNotes />
          </Route>
          <Route path="/public/:slug">
            <PublicPressKitScreen />
          </Route>
          <Route path="/reset-password/:uuid">
            <ForgotPasswordScreen />
          </Route>
          <Route path="/reset-password">
            <ForgotPasswordScreen />
          </Route>
          <Route path="/benefits">
            <BenefitsLanding />
          </Route>
          <Route path="/app">
            <DownloadApp />
          </Route>
          <Route path="/blog">
            <Article />
          </Route>
          <Route
            path="/ios-download"
            component={() => {
              window.location.href = 'https://apps.apple.com/ca/app/avoy/id1497419517';
              return null;
            }}
          />
          <Route
            path="/android-download"
            component={() => {
              window.location.href = 'https://play.google.com/store/apps/details?id=io.avoy.avoy';
              return null;
            }}
          />
          <Route
            path="/demo"
            component={() => {
              window.location.href = 'https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/demos/June-2020-Demo.mp4';
              return null;
            }}
          />
          <Route path="/access">
            <MembershipScreen />
          </Route>
          <Route path="/concierge">
            <Concierge />
          </Route>
          <Route path="/place/:slug">
            <PlaceScreen />
          </Route>
          <Route path="/trip/1">
            <SampleTrip />
          </Route>
          <Route path="/notification/:uuid">
            <NotificationScreen />
          </Route>
          <Route path="/passport/:displayName">
            <PassportScreen />
          </Route>
          <Route path="/">
            <NewLanding />
          </Route>
        </Switch>
      </div>
    </Fragment>
  );
};

export const WrappedMainRouter = withRouter(props => <MainRouter {...props} />);
