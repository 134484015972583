import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactMapGL from 'react-map-gl';
import styled from 'styled-components';

import { Pin } from './Pin';

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 20px;
`;

export const Map = ({ latitude, longitude, width = 600, pins = [] }) => {
  const [viewport, setViewport] = useState({
    width: isMobile ? 'calc(100vw - 80px)' : width,
    height: isMobile ? 'calc(100vw - 80px)' : width,
    latitude: latitude,
    longitude: longitude,
    zoom: 5,
  });

  return (
    <Wrapper>
      <ReactMapGL
        {...viewport}
        mapStyle={'mapbox://styles/travelwithyonder/cjuwweh1304j01fph2ysexxeq'}
        onViewportChange={setViewport}
      >
        {pins && pins.length > 0 && pins.map(pin => <Pin pin={pin} key={pin.uuid} />)}
      </ReactMapGL>
    </Wrapper>
  );
};
