import React, { Fragment } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import withHelmet from '../../utils/with-helmet';

import { LText, Divider } from '@avoy/core';

import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { getPlaceAction } from '../../api/api-actions';
import { FlagIcon } from '../../components/FlagIcon';
import { Photo } from '../../components/Photo';
import { Hub } from '../../components/Hub';
import { Map } from '../../components/maps/Map';
import { useApi } from '../../api/use-api';
import { removeDuplicates } from '../../utils/pins';
import { useWindowDimensions } from '../../utils/use-window';

const Head = styled.div`
  width: 100%;
  border-radius: 50px;
  background-color: var(--white);
  margin: auto;
  margin-top: -150px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 600px;
    border-radius: 100px;
  }
`;

const InnerContainer = styled.div`
  padding: 40px;
`;

const CountryTitle = styled(LText)`
  text-transform: uppercase;
  color: var(--light-black);
  margin-left: 8px;
`;

const Row = styled.div`
  display: inline-flex;
  align-items: center;
`;

const WidthManager = styled.div`
  max-width: calc(100vw - 80px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    width: 520px;
  }
`;

const NakedPlaceScreen = props => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  const { match } = props;
  const slug = match.params.slug || '';

  const [{ data, loading, error }] = useApi(getPlaceAction(slug));

  if (loading) {
    return null;
  }

  if (error) {
    // show not found!
    history.push('/');
    return null;
  }

  const Wrapper = styled.div`
    text-align: center;
    height: 90vh;
    width: 100vw;
    background: var(--light-black) url(${data.cover_photo.url}) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  `;

  const Title = styled.span`
    font-family: 'Domine', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: ${Math.min(70, width / (data.title.length * 0.87))}px;
    color: var(--black);

    @media only screen and (min-width: 768px) {
      font-size: ${Math.min(120, 520 / (data.title.length * 0.87))}px;
    }
  `;

  const hubs = data.relationships.filter(relationship => relationship.relationship_type === 'HUB');

  return (
    <Fragment>
      <Wrapper />
      <Head>
        <InnerContainer>
          <Row>
            <FlagIcon code={data.iso_code_2} />
            <CountryTitle>{data.country_title}</CountryTitle>
          </Row>
          <Divider height={5} />
          <Title>{data.title}</Title>
          <Divider height={15} />
          <WidthManager>
            {data.overview && <LText>{data.overview}</LText>}
            <Divider height={60} />
            <Divider height={60} />
            {data.full_description && (
              <Fragment>
                <h2>Why should you visit {data.title}?</h2>
                <Divider height={2} width={100} color={'var(--secondary)'} solid />
                <Divider height={60} />
                <LText style={{ textAlign: 'justify' }}>{data.full_description}</LText>
                <Divider height={60} />
              </Fragment>
            )}
            {data.photos &&
              data.photos.length > 0 &&
              data.photos.map(photo => (
                <Fragment key={photo.url}>
                  <Photo url={photo.url} name={photo.name} username={photo.username} />
                  <Divider height={20} />
                </Fragment>
              ))}
            <h2>Where is {data.title}?</h2>
            <Divider height={2} width={100} color={'var(--secondary)'} solid />
            <Divider height={60} />
            <Map
              width={520}
              latitude={data.geolocation.latitude}
              longitude={data.geolocation.longitude}
              pins={[
                { pin_type: 'TARGET', place: data, uuid: data.uuid },
                ...removeDuplicates(
                  data.relationships.map(relationship => {
                    return {
                      pin_type: relationship.relationship_type,
                      place: relationship.relationship_with,
                      uuid: relationship.relationship_with.uuid,
                    };
                  })
                ),
              ]}
            />
            {hubs.length > 0 && (
              <Fragment>
                <h4>Nearby Hubs</h4>
                <Divider height={2} width={100} color={'var(--secondary)'} solid />
                <Divider height={20} />
                {hubs.map(hub => (
                  <Hub hub={hub} key={hub.uuid} />
                ))}
              </Fragment>
            )}
          </WidthManager>
        </InnerContainer>
      </Head>

      <AvoyFooter />
    </Fragment>
  );
};

export const PlaceScreen = withRouter(props => <NakedPlaceScreen {...props} />);
