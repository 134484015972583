import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Sidebar from 'react-sidebar';

import { AccentBorderButton } from '@avoy/core';
import { Logo } from './Logo';
import { MobileMenu } from './MobileMenu';

const HeaderContainer = styled.div`
  width: calc(100% - 80px);
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 40px;
  z-index: 1;
`;

const MobileHeaderContainer = styled(HeaderContainer)`
  width: calc(100% - 40px);
  padding: 10px 20px;
  top: 0;
`;

const HeaderLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;

const HeaderLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 20px;
  background-color: var(--white);
  margin-left: 40px;
`;

export const BenefitsHeader = ({ selected }) => {
  const [headerStyle, setHeaderStyle] = useState({
    background: 'none',
  });
  const [fontStyle, setFontStyle] = useState({
    color: 'var(--white)',
    ':hover': 'var(--accent)',
  });
  const [isVisible, setIsVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isVisible = currPos.y < -100;

      const shouldBeStyle = {
        background: isVisible ? 'var(--white)' : 'none',
        transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
        boxShadow: isVisible ? '0px 4px 4px rgba(0,0,0,0.15)' : 'none',
      };

      const shouldBeFontStyle = {
        color: isVisible ? 'var(--black)' : 'var(--white)',
        transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
      };

      if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return;

      setHeaderStyle(shouldBeStyle);
      setFontStyle(shouldBeFontStyle);
      setIsVisible(isVisible);
    },
    [headerStyle, fontStyle]
  );

  // MOBILE
  if (isMobile) {
    return (
      <Sidebar
        sidebar={<MobileMenu setShowMenu={setShowMenu} />}
        pullRight
        open={showMenu}
        onSetOpen={setShowMenu}
        styles={{ sidebar: { background: 'white', position: 'fixed' } }}
      >
        <MobileHeaderContainer style={{ ...headerStyle }}>
          <Logo dark={true} benefits={true} />
          <FontAwesomeIcon
            icon={faBars}
            color={'var(--black)'}
            style={{ marginRight: 10, marginTop: 8, cursor: 'pointer' }}
            onClick={() => setShowMenu(true)}
          />
        </MobileHeaderContainer>
      </Sidebar>
    );
  }

  return (
    <HeaderContainer style={{ ...headerStyle }}>
      <Logo dark={true} benefits={true} />
      <HeaderLinks>
        <HeaderLink href="mailto:hi@goavoy.com">
          <AccentBorderButton style={{ marginLeft: 40 }} onClick={f => f}>
            REQUEST EARLY ACCESS
          </AccentBorderButton>
        </HeaderLink>
      </HeaderLinks>
    </HeaderContainer>
  );
};
