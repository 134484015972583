import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const withHelmet = (
  title = 'Avoy: Discover somewhere unique',
  url = 'https://www.avoy.io/',
  description = 'Avoy is a travel inspiration app to discover the world’s best hidden holiday destinations.',
  imgUrl = 'https://images.unsplash.com/photo-1465401180489-ceb5a34d8a63?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2'
) => {
  return (
    <Fragment>
      <Helmet>
        <meta name="description" content={description} />
        <meta name="title" content={title} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imgUrl} />
      </Helmet>
    </Fragment>
  );
};

export default withHelmet;
