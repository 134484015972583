import React, { Fragment } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { H2, H4, Spacer, LText, MText } from '@lafabrica/core';

import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { getPlaceAction } from '../../api/api-actions';
import { FlagIcon } from '../../components/FlagIcon';
import { DestinationPhoto } from '../../components/DestinationPhoto';
import { Hub } from '../../components/Hub';
import { Map } from '../../components/maps/Map';
import { useApi } from '../../api/use-api';
import { removeDuplicates } from '../../utils/pins';
import { useWindowDimensions } from '../../utils/use-window';

const Head = styled.div`
  width: 100%;
  border-radius: 50px;
  background-color: var(--white);
  margin: auto;
  margin-top: -150px;
  text-align: left;

  @media only screen and (min-width: 768px) {
    width: 600px;
    border-radius: 100px;
  }
`;

const InnerContainer = styled.div`
  padding: 40px;
`;

const CountryTitle = styled(LText)`
  text-transform: uppercase;
  color: var(--light-black);
  margin-left: 8px;
`;

const Row = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
`;

const WidthManager = styled.div`
  max-width: calc(100vw - 80px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 520px;
  }
`;

const LinkText = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  color: var(--black);

  &:hover {
    color: var(--accent);
  }
`;

const Tag = styled.div`
  padding: 5px 15px;
  margin: 5px;
  background-color: var(--accent);
  border-radius: 8px;
  width: fit-content;
  color: var(--white);
  font-weight: bold;
`;

const DestinationCard = styled.div`
  width: 240px;
  margin: 10px 5px;
  cursor: pointer;
`;

export const SampleTrip = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  const Wrapper = styled.div`
    text-align: center;
    height: 90vh;
    width: 100vw;
    background: var(--light-black)
      url('https://images.unsplash.com/photo-1554743847-2604e27f3dd9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ')
      no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  `;

  return (
    <Fragment>
      <Wrapper />
      <Head>
        <InnerContainer>
          <WidthManager>
            <Row>
              <FlagIcon code={'es'} />
              <CountryTitle>Spain</CountryTitle>
            </Row>
            <H2 style={{ marginTop: 5 }}>A trip through Andalucía</H2>
            <MText>
              Because you saved down &nbsp;<LinkText onClick={() => history.push(`/place/cadiz-es`)}>Cádiz</LinkText>
              &nbsp;and <LinkText onClick={() => history.push(`/place/malaga-es`)}>Málaga</LinkText>.
            </MText>
          </WidthManager>

          <Spacer times={2} />
          <Tag>2-WEEK ADVENTURE</Tag>
          <Spacer times={2} />
          <LText>
            Andalucía, the southernmost autonomous community on the Spanish Peninsula is home to vibrant colours,
            flavours and personalities. Deriving its name from the Arabic word "al-Andalus", this region of Spain has
            been influenced greatly by Roman, Visigothic and Muslim rule over its illustrious history resulting in a
            stunning amalgamation of cultures and architectural styles.
            <Spacer />
            Home to some of continental Europe's hottest temperatures, direct access to the Mediterranean and welcoming
            tapas culture, Andalucía offers a healthy dose of the Spanish way of life.
            <Spacer />
            In this trip idea, start in Málaga and make your way through Cádiz and Andalucía's colourful capital Seville
            before going through Mérida on your way back to Madrid.
          </LText>
          <H4>Destinations</H4>
          <Row>
            <DestinationCard onClick={() => history.push(`/place/cadiz-es`)}>
              <DestinationPhoto
                url={
                  'https://images.unsplash.com/photo-1550181805-69ea010f219c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2'
                }
                name={'Vidar Nordli-Mathisen'}
                userName={'vidarnm'}
              />
              <Row>
                <FlagIcon code={'es'} />
                <LText style={{ marginLeft: 10 }}>
                  <LinkText>Cádiz</LinkText>
                </LText>
              </Row>
            </DestinationCard>
            <DestinationCard onClick={() => history.push(`/place/seville-es`)}>
              <DestinationPhoto
                url={
                  'https://images.unsplash.com/photo-1515627043029-d5fcda8b150d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2'
                }
                name={'Fabio Ballasina'}
                userName={'fabiolog'}
              />
              <Row>
                <FlagIcon code={'es'} />
                <LText style={{ marginLeft: 10 }}>
                  <LinkText>Seville</LinkText>
                </LText>
              </Row>
            </DestinationCard>
            <DestinationCard onClick={() => history.push(`/place/malaga-es`)}>
              <DestinationPhoto
                url={
                  'https://images.unsplash.com/photo-1512753360435-329c4535a9a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2'
                }
                name={'Willian Justen de Vasconcellos'}
                userName={'willianjusten'}
              />
              <Row>
                <FlagIcon code={'es'} />
                <LText style={{ marginLeft: 10 }}>
                  <LinkText>Málaga</LinkText>
                </LText>
              </Row>
            </DestinationCard>
            <DestinationCard onClick={() => history.push(`/place/merida-es`)}>
              <DestinationPhoto
                url={
                  'https://images.unsplash.com/photo-1552001643-8739184439bd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2'
                }
                name={'analuisa gamboa'}
                userName={'anigmb'}
              />
              <Row>
                <FlagIcon code={'es'} />
                <LText style={{ marginLeft: 10 }}>
                  <LinkText>Mérida</LinkText>
                </LText>
              </Row>
            </DestinationCard>
            <DestinationCard onClick={() => history.push(`/place/madrid-es`)}>
              <DestinationPhoto
                url={
                  'https://images.unsplash.com/photo-1543783207-ec64e4d95325?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2'
                }
                name={'Jorge Fernández Salas'}
                userName={'jorgefdezsalas'}
              />

              <Row>
                <FlagIcon code={'es'} />
                <LText style={{ marginLeft: 10 }}>
                  <LinkText>Madrid</LinkText>
                </LText>
              </Row>
            </DestinationCard>
          </Row>
          <H4>When to go</H4>
          <LText>
            Andalucía's stifling summer heat can be uncomfortable for many travellers, plan accordingly and travel on
            the shoulder months.
            <Spacer />
            As the region is home to many fiestas and celebrations check the upcoming calendar for celebrations going on
            in the region:{' '}
            <LinkText href="https://www.andalucia.com/festival/home.htm" target="_blank" rel="noreferrer noredirect">
              https://www.andalucia.com/festival/home.htm
            </LinkText>
          </LText>
          <Spacer times={2} />
          <Row>
            <Tag style={{ backgroundColor: 'var(--light-grey)', color: 'var(--black)' }}>January</Tag>
            <Tag style={{ backgroundColor: 'var(--light-grey)', color: 'var(--black)' }}>February</Tag>
            <Tag>March</Tag>
            <Tag>April</Tag>
            <Tag>May</Tag>
            <Tag style={{ backgroundColor: 'var(--light-grey)', color: 'var(--black)' }}>June</Tag>
            <Tag style={{ backgroundColor: 'var(--light-grey)', color: 'var(--black)' }}>July</Tag>
            <Tag style={{ backgroundColor: 'var(--light-grey)', color: 'var(--black)' }}>August</Tag>
            <Tag>September</Tag>
            <Tag>October</Tag>
            <Tag>November</Tag>
            <Tag>December</Tag>
          </Row>
          <Spacer times={2} />
          <MText>
            Want more trip ideas? <LinkText onClick={() => history.push(`/concierge`)}>Try Avoy Concierge</LinkText>
          </MText>
        </InnerContainer>
      </Head>

      <AvoyFooter />
    </Fragment>
  );
};
