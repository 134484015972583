import React, { useState } from 'react';
import styled from 'styled-components';

const keys = [
  { id: 'SOPHISTICATED', text: 'So' },
  { id: 'BOHEMIAN', text: 'Bo' },
  { id: 'HISTORIAN', text: 'Hi' },
  { id: 'OUTDOOR ENTHUSIAST', text: 'Ou' },
  { id: 'EXPLORER', text: 'Ex' },
  { id: 'ESCAPIST', text: 'Es' },
];

const HexagonOuter = styled.div`
  position: relative;
  width: 40px;
  height: 22px;
  background-color: var(--light-accent);
  margin: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    bottom: 100%;
    border-bottom: 11.54px solid var(--light-accent);
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    top: 100%;
    left: 0;
    border-top: 11.54px solid var(--light-accent);
  }
`;

const Text = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
`;

const Label = styled.div`
  position: absolute;
  top: -30px;
  right: -50px;
  padding: 5px 10px;
  background-color: var(--black);
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  color: var(--white);
  z-index: 10;
`;

export const Persona = ({ id }) => {
  const [showLabel, setShowLabel] = useState(false);

  function getText() {
    return keys.filter(key => key.id === id).map(key => key.text);
  }

  return (
    <HexagonOuter onMouseEnter={() => setShowLabel(true)} onMouseLeave={() => setShowLabel(false)}>
      {showLabel && <Label>{id}</Label>}
      <Text>{getText()}</Text>
    </HexagonOuter>
  );
};
