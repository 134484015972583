import React, { Fragment } from 'react';
import styled from 'styled-components';

import { SmallHeader, Divider, MText } from '@avoy/core';

import { PlaceBadge } from '../../components/PlaceBadge';

const Row = styled.div`
  display: flex;
  flex-decoration: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const StatsBullet = styled.div`
  height: 6px;
  width: 6px;
  background-color: var(--accent);
  border-radius: 50%;
  margin: 0 12px;
`;

export const PassportLocations = ({ data }) => {
  const countries = [...new Set(data.visited_detail.visits.map(item => item.iso_code_2))];

  console.log(data);
  return (
    <Fragment>
      <SmallHeader>Hidden Gems</SmallHeader>
      <Divider height={10} />
      <Divider height={1} width={30} solid={true} color="var(--secondary)" />
      <Divider height={20} />
      <Row>
        {data.hidden_gems.length === 0 ? (
          <MText>No hidden gems approved.</MText>
        ) : (
          data.hidden_gems.map((gem, i) => (
            <PlaceBadge key={i} title={gem.place.title} iso_code_2={gem.place.iso_code_2} country={false} />
          ))
        )}
      </Row>
      <Divider height={35} />
      <SmallHeader>Travels</SmallHeader>
      <Divider height={10} />
      <Divider height={1} width={30} solid={true} color="var(--secondary)" />
      <Divider height={20} />
      <Row>
        <StatsBullet />
        <MText>{data.visited_summary.countries} Countries</MText>
        <StatsBullet />
        <MText>{data.visited_summary.continents} Continents</MText>
      </Row>
      <Divider height={20} />
      <Row>
        {countries.map(code => {
          const countryTitle = data.visited_detail.visits
            .filter(item => item.iso_code_2 === code)
            .map(item => item.country_title)[0];

          // get all the places inside
          const indPlaces = data.visited_detail.visits.filter(
            item => item.iso_code_2 === code && item.destination_type !== 'COUNTRY'
          );

          return <PlaceBadge key={code} iso_code_2={code} title={countryTitle} places={indPlaces} country={true} />;
        })}
      </Row>
    </Fragment>
  );
};
