import React, { Fragment } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { alpha2ToAlpha3 } from 'i18n-iso-countries';

import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { getPassportAction } from '../../api/api-actions';
import { PassportMap } from '../../components/maps/PassportMap';
import { PassportContent } from './PassportContent';
import { useApi } from '../../api/use-api';
import { removeDuplicates } from '../../utils/pins';
import withHelmet from '../../utils/with-helmet';
import BounceLoader from 'react-spinners/BounceLoader';

const Wrapper = styled.div`
  width: 100vw;
  text-align: center;
`;

const FullScreen = styled.div`
  width: 100vw;
  height: auto;
  margin-bottom: 120px;
  text-align: center;
  @media only screen and (min-width: 768px) {
    margin-bottom: 65px;
  }
`;

const FloatingDiv = styled.div`
  max-width: 600px;
  display: inline-block;
  background-color: var(--white);
  z-index: 1;
`;

const InnerContainer = styled.div`
  padding: 20px;
`;
const EmptyPassportMap = styled.div`
  height: calc(100vh - 140px);
  background-color: var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    height: calc(100vh - 200px);
  }
`;

const NakedPassportScreen = props => {
  const history = useHistory();

  const { match } = props;
  const displayName = match.params.displayName || '';

  const [{ data, loading, error }] = useApi(getPassportAction(displayName));

  if (error) {
    // show not found!
    history.push('/');
    return null;
  }

  if (loading || !data) {
    return (
      <Wrapper>
        <FullScreen>
          <EmptyPassportMap>
            <BounceLoader size={100} color={'var(--accent)'} />
          </EmptyPassportMap>
          <PassportContent data={{}} loading />
        </FullScreen>
        <AvoyFooter />
      </Wrapper>
    );
  }

  const visits = data.visited_detail.visits.map(visit => {
    return {
      pin_type: 'VISIT',
      place: visit,
      uuid: visit.uuid,
    };
  });
  const saves = data.visited_detail.saves.map(save => {
    return {
      pin_type: 'SAVE',
      place: save,
      uuid: save.uuid,
    };
  });

  const visitedCountries = [...visits.map(visit => visit.place), data.current_city]
    .map(visit => visit.iso_code_2)
    .map(iso => iso.toUpperCase())
    .map(alpha2ToAlpha3);

  return (
    <Wrapper>
      {withHelmet(
        undefined,
        `https://www.avoy.io/passport/${displayName}`,
        `${data.first_name} has been to ${data.visited_summary.countries} countries - check out their passport to see where!`,
        'https://images.unsplash.com/photo-1515627043029-d5fcda8b150d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2'
      )}
      <FullScreen>
        <PassportMap
          pins={[
            { pin_type: 'CURRENT_CITY', place: data.current_city, uuid: data.current_city.uuid },
            ...removeDuplicates([...visits, ...saves]),
          ]}
          visitedCountries={visitedCountries}
        />
        <PassportContent data={data} />
      </FullScreen>
      <AvoyFooter />
    </Wrapper>
  );
};

export const PassportScreen = withRouter(props => <NakedPassportScreen {...props} />);
