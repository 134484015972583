import React, { Fragment } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

import { LText, MText, Divider, SText, SmallHeader } from '@avoy/core';

import { Persona } from '../../components/Persona';
import { PassportLocations } from './PassportLocations';
import { useApi } from '../../api/use-api';
import { getName, getInitials } from '../../utils/name';
import BeatLoader from 'react-spinners/BeatLoader';

const Row = styled.div`
  display: flex;
  flex-decoration: row;
  align-items: center;
`;

const InnerContainer = styled.div`
  max-width: calc(100vw - 80px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    width: 520px;
  }
`;

const ProfileAvatar = styled.div`
  height: 100px;
  width: 100px;
  margin: auto;
  position: relative;
  border-radius: 50%;
  background-color: var(--accent);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid white;
  margin-top: -50px;

  @media only screen and (min-width: 768px) {
    height: 200px;
    width: 200px;
    margin-top: -100px;
  }
`;

const Logo = styled.img`
  width: 100%;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 22px;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

export const PassportContent = ({ data, isMobile = false, loading = false }) => {
  // handle loading
  if (loading) {
    return (
      <InnerContainer>
        <ProfileAvatar>
          <BeatLoader size={15} color={'var(--white)'} />
        </ProfileAvatar>
      </InnerContainer>
    );
  }

  const name = getName(data.first_name, data.last_name);
  const countries = data.visited_detail.visits;

  return (
    <InnerContainer>
      <ProfileAvatar>
        <Logo src="https://avoy-assets.nyc3.digitaloceanspaces.com/press/logo-circle-1024-purple.png" />
      </ProfileAvatar>
      <Title>{name}</Title>
      <Divider height={5} />
      <MText>{data.current_city.title}</MText>
      <Divider height={35} />
      <Row>
        {data.personas.map(item => (
          <Persona id={item} key={item} />
        ))}
      </Row>
      <Divider height={35} />
      <SmallHeader>About</SmallHeader>
      <Divider height={10} />
      <Divider height={1} width={30} solid={true} color="var(--secondary)" />
      <Divider height={20} />
      <MText>{data.description}</MText>
      <Divider height={35} />
      <PassportLocations data={data} />
    </InnerContainer>
  );
};
