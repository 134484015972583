export function truncate(string, word_count, prefix = false, suffix = false) {
  let split = string.split(' ');
  const original_length = split.length;

  let text = prefix ? '...' : '';
  text = text + split.splice(0, Math.min(split.length, word_count)).join(' ');
  if (original_length > word_count) {
    return text + '...';
  }

  return text + (suffix ? '...' : '');
}
