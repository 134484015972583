import React, { Fragment } from 'react';
import styled from 'styled-components';

import { LText, Divider, AccentButton } from '@avoy/core';
import { truncate } from '../../utils/text';

const PartnerTitle = styled(LText)`
  text-transform: uppercase;
  color: var(--light-black);
`;

const A = styled.a`
  text-decoration: none;
`;

export const Notification = ({ data }) => {
  return (
    <Fragment>
      <h2 style={{ textAlign: 'center' }}>{data.title}</h2>
      <PartnerTitle>{data.partner.name}</PartnerTitle>
      <Divider height={20} />
      <Divider height={2} width={100} color={'var(--secondary)'} solid />
      <Divider height={60} />
      <LText style={{ textAlign: 'justify' }}>{truncate(data.text, 100)}</LText>
      <Divider height={60} />
      <A href={data.url} target="_blank" rel="noopener noreferrer">
        <AccentButton>READ MORE</AccentButton>
      </A>
      <Divider height={60} />
    </Fragment>
  );
};
