import React, { useState, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import { Spacer, H3, H4 } from '@lafabrica/core';
import { SText, AccentButton, MText, WhiteButton } from '@avoy/core';

import { registerWaitlist, inviteWaitlist } from '../../api/api-requests';
import { Logo } from '../../components/header/Logo';
import { TextField } from '../../components/TextField';

const width = window.innerWidth;
const inputWidth = Math.min(width - 40, 360);

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  background-color: var(--white);
`;

const CloseWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--black);

  @media only screen and (min-width: 769px) {
    right: 40px;
    top: 40px;
    width: 80px;
  }
`;

const Side = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 769px) {
    width: 50vw;
  }
`;

const LeftSide = styled(Side)`
  height: 100vh;
  background: var(--light-black)
    url('https://images.unsplash.com/photo-1465401180489-ceb5a34d8a63?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2')
    no-repeat center;
`;

const RightSide = styled(Side)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  align-items: center;

  @media only screen and (min-width: 769px) {
    min-width: 540px;
    margin-bottom: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 0 20px;
  align-items: flex-start;
  text-align: left;

  @media only screen and (min-width: 769px) {
    width: 360px;
  }
`;

const A = styled.a`
  color: var(--black);
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: var(--accent);
  }
`;

export const MembershipScreen = () => {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [hiddengem, setHiddenGem] = useState(undefined);
  const [inviteEmail, setInviteEmail] = useState(undefined);
  const [errors, setErrors] = useState({});
  const [inviting, setInviting] = useState(false);

  function submit() {
    setStep(2);
    registerWaitlist({ name, email, hiddengem });
  }

  async function invite() {
    await setInviting(true);
    await inviteWaitlist({ name, inviteEmail });
    setTimeout(() => {
      setInviteEmail('');
      setInviting(false);
    }, 1000);
  }

  function handleNameChange(value, errors) {
    setName(value);

    if (value && value.length > 2) {
      errors.name = null;
      setErrors(errors);
      return;
    }
    errors.name = 'Please add your name';

    setErrors(errors);
  }

  function handleEmailChange(value, errors, inviteEmail = false) {
    if (inviteEmail) {
      setInviteEmail(value);
    } else {
      setEmail(value);
    }

    if (value && value.length > 0) {
      if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        errors.email = 'Please enter a valid email address.';
      } else {
        errors.email = null;
      }
    } else {
      errors.email = 'Required.';
    }
    setErrors(errors);
  }

  const valid = email && name && Object.values(errors).filter(a => a).length === 0;
  const validTwo = hiddengem && hiddengem.length > 3;

  return (
    <Wrapper>
      <CloseWrapper onClick={() => history.goBack()}>
        <FontAwesomeIcon icon={faTimes} size={isMobile ? '1x' : '2x'} />
      </CloseWrapper>
      <LeftSide />
      <RightSide>
        <Content>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Logo dark big noFlex />
          </div>
          <Spacer />
          {step === 0 && (
            <Fragment>
              <H4 style={{ marginBottom: 10 }}>Travel Done Right</H4>
              <MText>
                We believe in wandering off the beaten path, exploring new cultures, and the life-changing power of
                authentic travel experiences.
                <Spacer />
                Join us in our mission to change travel and&nbsp;
                <br />
                <u>discover somewhere unique</u>.
              </MText>
              <Spacer times={2} />
              <TextField
                label="Full Name"
                name={'name'}
                value={name}
                setValue={v => handleNameChange(v, errors)}
                style={{ width: inputWidth }}
              />
              {errors.name && <SText style={{ color: 'var(--red)' }}>{errors.name}</SText>}
              <Spacer />
              <TextField
                label="Email"
                name={'email'}
                value={email}
                setValue={v => handleEmailChange(v, errors)}
                style={{ width: inputWidth }}
              />
              {errors.email && <SText style={{ color: 'var(--red)' }}>{errors.email}</SText>}
              <Spacer times={2} />
              <AccentButton onClick={valid ? () => setStep(1) : f => f} style={{ width: '100%' }} disabled={!valid}>
                Continue
              </AccentButton>
            </Fragment>
          )}
          {step === 1 && (
            <Fragment>
              <H4 style={{ marginBottom: 10 }}>A community of explorers</H4>
              <MText>
                Avoy is built on the recommendations of expert travellers from around the globe.
                <Spacer />
                Contribute to the community at Avoy by recommending a hidden gem of your own.
              </MText>
              <Spacer times={2} />
              <div style={{ textAlign: 'left' }}>
                <TextField
                  label="What hidden destination(s) do you know about?"
                  name={'hiddengem'}
                  value={hiddengem}
                  setValue={setHiddenGem}
                  style={{ width: inputWidth }}
                  placeholder="Tromso, Norway"
                />
              </div>
              <Spacer times={2} />
              <AccentButton onClick={validTwo ? submit : f => f} style={{ width: '100%' }} disabled={!validTwo}>
                Submit
              </AccentButton>
              <Spacer times={0.5} />
              <SText style={{ textAlign: 'center' }}>
                You are more likely to receive an invite code if we don't know about the destination(s) already.{' '}
              </SText>
              <Spacer times={0.5} />
              <SText>
                By clicking "Submit" you agree to &nbsp;
                <A
                  href="https://www.termsfeed.com/terms-conditions/573f198132a2dceea013fa02a04a825e"
                  target="_blank"
                  noopener
                  noreferrer
                >
                  Terms and Conditions
                </A>{' '}
                and &nbsp;
                <A href="https://www.iubenda.com/privacy-policy/26986804" target="_blank" noopener noreferrer>
                  Privacy Policy
                </A>
              </SText>
            </Fragment>
          )}
          {step === 2 && (
            <Fragment>
              <H4 style={{ marginBottom: 10 }}>We received your application to join Avoy!</H4>
              <MText>
                We onboard new users as we add new destinations to Avoy's database to ensure that we keep travel
                sustainable.
                <Spacer />
                You've been added to our waiting list and will receive a text message with a 6-digit registration code
                and a link to download our application shortly.
              </MText>
              <Spacer times={3} />
              <MText>
                <strong>Want to climb up the waiting list?</strong>
                <br />
                Invite fellow explorers to jump the queue.
              </MText>
              <Spacer />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                <TextField
                  label={`Email`}
                  name={'invite'}
                  value={inviteEmail}
                  setValue={v => handleEmailChange(v, errors, true)}
                  style={{ width: inputWidth - 90, minWidth: 'auto' }}
                  placeholder="globetrotter@avoy.io"
                />
                <AccentButton
                  onClick={inviting ? f => f : invite}
                  style={{ width: 90, minWidth: 'auto' }}
                  disabled={inviting || errors.email}
                >
                  {inviting ? 'Sending' : 'Invite'}
                </AccentButton>
              </div>
            </Fragment>
          )}
        </Content>
      </RightSide>
    </Wrapper>
  );
};
