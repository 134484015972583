import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
  display: inline-block;
  overflow: hidden;
  background: url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2020-02-13&kind=iossoftware&bubble=ios_apps)
    no-repeat;
  width: 135px;
  height: 40px;
`;

export const AppleLink = ({ style }) => (
  <Container style={style && style} href="https://apps.apple.com/ca/app/avoy/id1497419517?mt=8" />
);
