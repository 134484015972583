export function getName(first_name, last_name) {
  return first_name + ' ' + last_name;
}

export function getInitials(name) {
  const names = name.split(' ');
  // Let's get rid of blanks...
  for (var i = 0; i < names.length; i++) {
    if (names[i] === '') {
      names.splice(i, 1);
    }
  }
  return names
    .slice(0, Math.min(2, names.length))
    .map(n => {
      if (n[0]) return n[0].toUpperCase();
    })
    .join('');
}
