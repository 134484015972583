import React, { Fragment } from 'react';
import styled from 'styled-components';

import { LText, Divider, AccentButton } from '@avoy/core';

import { truncate } from '../../utils/text';
import { getAttribute } from '../../utils/attributes';

const PartnerTitle = styled(LText)`
  text-transform: uppercase;
  color: var(--light-black);
`;

const A = styled.a`
  text-decoration: none;
`;

export const FlightDeal = ({ data }) => {
  const price = getAttribute('PRICE_USD', data.attributes);

  return (
    <Fragment>
      <h2 style={{ textAlign: 'center' }}>
        {data.title} ${price}
      </h2>
      <PartnerTitle>via {data.partner.name}</PartnerTitle>
      <Divider height={20} />
      <Divider height={2} width={100} color={'var(--secondary)'} solid />
      <Divider height={60} />
      <LText style={{ textAlign: 'center' }}>
        Click view flight below to see more information about the deal from our partners.
      </LText>
      <Divider height={60} />
      <A href={data.source.url} target="_blank" rel="noopener noreferrer">
        <AccentButton>VIEW FLIGHT</AccentButton>
      </A>
      <Divider height={60} />
    </Fragment>
  );
};
