import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import InstagramEmbed from 'react-instagram-embed';

import { LText, Divider, AccentButton } from '@avoy/core';
import { truncate } from '../../utils/text';
import { getAttribute } from '../../utils/attributes';

const PartnerTitle = styled(LText)`
  text-transform: lowercase;
  color: var(--light-black);
`;

const A = styled.a`
  text-decoration: none;
`;

const InstagramWrapper = styled.div`
  max-width: 100vw;

  @media only screen and (min-width: 768px) {
    width: 520px;
  }
`;

export const Instagram = ({ data }) => {
  let account = getAttribute('INSTAGRAM_ACCOUNT', data.attributes);
  let photo = getAttribute('INSTAGRAM_PHOTO', data.attributes);

  let url = `https://www.instagr.am/${account}`;

  return (
    <Fragment>
      <h2 style={{ textAlign: 'center' }}>{data.title}</h2>
      <A href={url} target="_blank" rel="noopener noreferrer">
        <PartnerTitle>
          <FontAwesomeIcon icon={faInstagram} style={{ marginRight: 10 }} />
          {account}
        </PartnerTitle>
      </A>
      <Divider height={20} />
      <Divider height={2} width={100} color={'var(--secondary)'} solid />
      <Divider height={60} />
      <LText style={{ textAlign: 'justify' }}>{truncate(data.text, 100)}</LText>
      <Divider height={30} />
      <InstagramWrapper>
        <InstagramEmbed url={`https://instagr.am/p/${photo}/`} maxWidth={520} />
      </InstagramWrapper>
      <Divider height={60} />
      <A href={url} target="_blank" rel="noopener noreferrer">
        <AccentButton>GO TO ACCOUNT</AccentButton>
      </A>
      <Divider height={60} />
    </Fragment>
  );
};
