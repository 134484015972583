import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import { Spacer, H2, H4, LText, MText } from '@lafabrica/core';

import { AvoyFooter } from '../../components/footer/AvoyFooter';

const Wrapper = styled.div`
  width: 100vw;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 80px auto;
  max-width: 600px;
  padding: 20px;
  text-align: left;
`;

const A = styled.a`
  text-decoration: none;
  color: var(--accent);
`;

const Image = styled.img`
  width: 600px;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

const Item = ({ image, title, overview, description, knownFor, savePercentage, travelTip = null }) => (
  <Fragment>
    <Image src={image} />
    <Spacer />
    <H4 style={{ marginBottom: 10 }}>{title}</H4>
    <MText>
      <FontAwesomeIcon icon={faHeart} color={'var(--red)'} style={{ marginRight: 10 }} />
      {savePercentage} save rate
    </MText>
    <Spacer />
    <LText>
      {overview}
      <Spacer />
      {description}
      <Spacer />
      {title} is known for {knownFor}
      <Spacer />
      {travelTip && <i>Travel tip: {travelTip}</i>}
      <Spacer times={2} />
    </LText>
  </Fragment>
);

const content = [
  {
    image: 'https://images.unsplash.com/photo-1505753065532-68713e211a3d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ',
    title: 'Étretat',
    overview: "A picturesque beach town on France's north coast famous for its sea cliffs",
    description:
      'With its eye-catching sea cliff formations dominating the coastline, this picturesque Normandy town is the ideal setting for being adventurous or relaxing in style. It boasts stunning cliff walks; a historic, wood-covered market; and opportunities for plenty of adventure sports on the water.',
    knownFor: 'Beaches, Trekking, and Art',
    travelTip:
      'From Paris, you can access the Flixbus service, but a shorter journey from Le Havre is ideal. From the Le Havre train station, the local bus has you in Étretat in 40 minutes.',
    savePercentage: '59%',
  },
  {
    image: 'https://images.unsplash.com/photo-1578240749366-a1d3b6c39cb4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ',
    title: 'Èze',
    overview: 'A stunning medieval town located on the French Riviera',
    description:
      'Located between Nice and Monaco, Èze is a hilltop town originally constructed as a fortress during medieval times, and it still has the architecture to prove it. Spend time exploring the beautiful gardens and chateaus before soaking up the panoramic Mediterranean views while sipping a glass of French wine.',
    knownFor: 'Wine, and the French Riviera Coast',
    travelTip: 'Visit during the spring and fall shoulder months to avoid the influx of summer tourists.',
    savePercentage: '57%',
  },
  {
    image: 'https://images.unsplash.com/photo-1568997207915-51a23f6c1d16?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ',
    title: 'Belle-Île-en-Mer',
    overview:
      'A small secluded island off the western coast of France known only by locals for its abundance of spectacular views',
    description:
      'Belle-Ile-en-Mer directly translates to Beautiful Island at Sea, and the name could not ring more true. Rugged cliffs with waves from the Atlantic crashing up against them make for awe-inspiring views as dozens of beaches with what feels like eternal sunshine act as the best way to spend your days relaxing. Rent a bike to tour the entire island and discover the untouched beauty and the magnetic peaceful vibe that makes people never want to leave.',
    knownFor: 'Trekking, Aquatic Sports, and Nature',
    travelTip:
      'To get to Belle-Ile-en-Mer you will need to take the ferry from Quiberon which is easily accessible by train or bus to nearby big cities.',
    savePercentage: '65%',
  },
  {
    image: 'https://images.unsplash.com/photo-1581551680619-5499efb594ba?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ',
    title: 'Hyères',
    overview: 'A crowd-free way to experience the awe-inspiring beauty of the French Riviera',
    description:
      'For travellers wanting a relaxing Mediterranean escape, Hyères offers incredible seaside views and an authentic French experience not soon forgotten. Considered the original French Riveria, you could be forgiven for thinking you were in Cannes or St. Tropez—minus the crowds, of course. Hyères is often used as a launchpad for exploring the Île de Porquerolles or the many nearby islands that offer unrivalled hiking and lounging, plus a serenity not found anywhere else in the French Riviera.',
    knownFor: 'Beaches, Food, and Serenity',
    travelTip:
      'Hyères is about halfway between Marseille and Nice and accessible via train or the Toulon-Hyères airport.',
    savePercentage: '60%',
  },
  {
    image: 'https://images.unsplash.com/photo-1562747981-1975c624e26e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ',
    title: 'Carmague',
    overview:
      'A wilder and lesser-known side of la Provence in southern France that offers a glimpse into the Romanesque side of the country',
    description:
      'The mosaic of different cultures and colours that you’ll find in the region of Camargue is so strikingly different than the rest of the country it will make you wonder if you’re still in France. Its unique diversity reaches across the likes of ancient Rome in the historic city of Arles, lush rice fields on the banks of the Rhone and incredible amounts of wildlife, most notably the pink flamingos and the resident wild horses, in the national parks. Take a road trip through the region and discover all its distinctive niche pockets and see a wilder side of France.',
    knownFor: 'Wildlife, Roman History, and Nature',
    travelTip:
      'The best way to discover the region is by car. If you don’t have your own car you can rent one from Montpellier or Marseille, both of which are very close to Camargue.',
    savePercentage: '68%',
  },
];

export const Article = () => (
  <Wrapper>
    <Content>
      <H2>Hidden gems you need to visit in France</H2>
      <Row>
        <Avatar src="https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/press/cody-dillabough-founder.jpg" />
        <div>
          <MText>
            <strong>Cody Dillabough</strong>
            <br />
            September 16, 2020
          </MText>
        </div>
      </Row>
      <Spacer />
      <Image src="https://images.unsplash.com/photo-1549416878-ceda3534842b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ" />
      <Spacer times={2} />
      <LText>
        Think you know France? Don't miss out on these beautiful hidden destinations.
        <Spacer times={2} />
        <i>
          For more hidden travel destinations check out the <u>Avoy App</u> for daily recommendations on hidden travel
          destinations.
        </i>
      </LText>
      <Spacer times={2} />
      {content.map(item => (
        <Item
          image={item.image}
          key={item.title}
          title={item.title}
          overview={item.overview}
          description={item.description}
          knownFor={item.knownFor}
          travelTip={item.travelTip}
          savePercentage={item.savePercentage}
        />
      ))}
    </Content>
    <AvoyFooter />
  </Wrapper>
);
