import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Divider, LText, MText } from '@avoy/core';

import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { CenterHeader } from '../../components/CenterHeader';

const Wrapper = styled.div`
  text-align: center;
  height: 90vh;
  width: 100vw;
  background: var(--light-black)
    url('https://images.unsplash.com/photo-1473951574080-01fe45ec8643?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjUzMDc1fQ&h=800&dpi=2')
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px auto;
  max-width: 600px;
  padding: 20px;
  text-align: center;
`;

const AccentLink = styled.a`
  font-size: 20px;
  color: var(--black);
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
`;

const PhotoContainer = styled.div`
  width: 250px;
  max-height: 250px;
  border-radius: 20px;
  overflow: hidden;
`;

const PressLink = styled.a`
  margin: 20px;
`;

export const Press = () => (
  <Fragment>
    <Wrapper>
      <Divider height={60} />
      <CenterHeader title="Press Information" />
    </Wrapper>
    <Content>
      <h4>Our Story</h4>
      <Divider height={2} color={'var(--accent)'} width={100} solid />
      <Divider height={40} />
      <LText>
        The idea for Avoy was born from a real-world frustration our founding team kept experiencing: with such
        advancements in travel technology and the abundance of travel photos on social media, why is it still so hard to
        plan <em>where to go next</em>?
      </LText>
      <Divider height={30} />
      <LText>
        We can now travel to once faraway lands with affordable airfare and comprehensive guides, but we still don’t
        know where we should be heading. Whether it be via word-of-mouth, an ad infested slideshow or a coffee-stained
        travel magazine in the pocket of your airplane seat, we believe that now is the time to change how you discover
        your next destination.
      </LText>
      <Divider height={30} />
      <LText>
        We want to change the way that we think about travel and want to encourage you to{' '}
        <em>discover somewhere unique</em>.
      </LText>
      <Divider height={80} />
      <h4>In the Press</h4>
      <Divider height={2} color={'var(--accent)'} width={100} solid />
      <Divider height={80} />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
        <PressLink
          href="https://workhaus.ca/member-spotlight/workhaus-member-spotlight-cody-dillabough-avoy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://workhaus.ca/wp-content/uploads/2018/08/Workhaus-Logo.png"
            style={{ width: 200, opacity: 0.4 }}
          />
        </PressLink>
        <PressLink
          href="https://startupheretoronto.com/type/profiles/avoy-answers-the-question-where-should-i-go-next/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://startupheretoronto.com/wp-content/uploads/2017/05/StartUpHEREToronto_logo_K_RGB.png"
            style={{ width: 200, opacity: 0.4 }}
          />
        </PressLink>
      </div>
      <Divider height={80} />
      <h4>Leadership</h4>
      <Divider height={2} color={'var(--accent)'} width={100} solid />
      <Divider height={40} />
      <LText>
        <PhotoContainer>
          <a
            href="https://avoy-assets.nyc3.digitaloceanspaces.com/press/cody-dillabough-founder.jpg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/press/cody-dillabough-founder.jpg"
              alt="Cody Dillabough"
              style={{ width: 250 }}
            />
          </a>
        </PhotoContainer>
        <Divider height={20} />
        <AccentLink href="https://www.linkedin.com/in/cody-dillabough-cfa-43101a55/" target="_blank" noreferrer>
          <strong>Cody Dillabough</strong>
        </AccentLink>
        <br />
        CEO & Founder
      </LText>
      <Divider height={80} />
      <h4>Assets</h4>
      <Divider height={2} color={'var(--accent)'} width={100} solid />
      <Divider height={40} />
      <PhotoContainer>
        <a
          href="https://avoy-assets.nyc3.digitaloceanspaces.com/press/logo-text-755-black.png"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/press/logo-text-755-black.png"
            alt=""
            style={{ width: 250 }}
          />
        </a>
      </PhotoContainer>
      <Divider height={40} />
      <PhotoContainer>
        <a
          href="https://avoy-assets.nyc3.digitaloceanspaces.com/press/logo-circle-1024-purple.png"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/press/logo-circle-1024-purple.png"
            alt=""
            style={{ width: 250 }}
          />
        </a>
      </PhotoContainer>
      <Divider height={60} />
      <LText>
        For press inquiries please contact&nbsp;
        <AccentLink href="mailto:hi@avoy.io" target="_blank" noreferrer>
          <strong>hi@avoy.io</strong>
        </AccentLink>
      </LText>
    </Content>
    <AvoyFooter />
  </Fragment>
);
