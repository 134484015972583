import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { Divider, LText, AccentButton } from '@avoy/core';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100vw;
`;

const HeaderSection = styled.div`
  height: calc(100vh - 50px);
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;

  @media only screen and (min-width: 769px) {
    height: calc(100vh - 100px);
    margin-bottom: 0px;
    padding-left: 80px;
    padding-right: 80px;
  }

  @media only screen and (min-width: 1600px) {
    max-width: 1600px;
    margin: auto;
  }
`;

const HeaderText = styled.div`
  width: 100%;

  @media only screen and (min-width: 1114px) {
    width: calc(100vw - 720px);

    max-width: 480px;
  }

  @media only screen and (min-width: 1200px) {
    width: 480px;
  }
`;

const BenefitsLandingImage = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  z-index: 0;

  @media only screen and (min-width: 769px) {
    max-width: 600px;
    opacity: 0.3;
    right: 0;
  }

  @media only screen and (min-width: 1114px) {
    opacity: 1;
    left: calc(100vw - 720px + 120px);
    width: 600px;
    max-width: 700px;
  }

  @media only screen and (min-width: 1200px) {
    left: 600px;
    width: calc(100vw - 600px);
  }

  @media only screen and (min-width: 1300px) {
    width: calc(100vw - 700px);
  }

  @media only screen and (min-width: 1600px) {
    left: 50vw;
  }
`;

const StripeContainer = styled.div`
  min-height: 260px;
  background-color: var(--accent);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 80px;

  @media only screen and (min-width: 769px) {
    display: inline-flex;
    flex-direction: row;
  }
`;

const Stat = styled.div`
  flex-direction: column;
  margin: auto;
  text-align: center;
  color: var(--white);
  padding: 30px 20px;
  padding-bottom: 60px;
`;

const Row = styled.div`
  padding-top: 60px;
  display: inline-flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media only screen and (min-width: 769px) {
    padding-top: 120px;
  }
`;

const DesktopContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 40px;

  @media only screen and (min-width: 769px) {
    max-width: 400px;
    text-align: left;
  }
`;

const CenteredDesktopContainer = styled(DesktopContainer)`
  @media only screen and (min-width: 769px) {
    max-width: 700px;
    text-align: center;
  }
`;

const SmallTitle = styled.div`
  color: var(--black);
  border-bottom: 1px solid var(--light-grey);
  width: fit-content;
  line-height: 36px;
  margin: auto;

  @media only screen and (min-width: 769px) {
    margin: inherit;
  }
`;

const StyledLink = styled.a`
  color: var(--black);
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
`;
const BlankLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

const Circle = styled.div`
  display: flex;
  height: 200px;
  width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  border: 1px solid var(--light-black);
  border-radius: 50%;

  @media only screen and (min-width: 769px) {
    height: 350px;
    width: 350px;
    padding: 30px;
  }
`;

export const BenefitsLanding = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <HeaderSection>
        <HeaderText>
          <Divider height={40} />
          <h2>Reward your talent with travel</h2>
          <Divider height={20} />
          <LText>
            <strong>Attract, retain & inspire</strong>
            &nbsp;top talent through personal travel benefits with Avoy Benefits.
          </LText>
          <Divider height={40} />
          <BlankLink href="mailto:hi@goavoy.com">
            <AccentButton style={{ maxWidth: 250 }}>REQUEST EARLY ACCESS</AccentButton>
          </BlankLink>
        </HeaderText>
        <BenefitsLandingImage>
          <img
            alt=""
            style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '90vh' }}
            src="https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/landing/business-landing-graphic.png"
          />
        </BenefitsLandingImage>
      </HeaderSection>
      <StripeContainer>
        <Stat>
          <h1>#1</h1>
          <LText>Criteria for job seekers is a strong work-life balance</LText>
        </Stat>
        <Stat>
          <h1>82%</h1>
          <LText>of employees report an increase in job performance upon return to work after travel</LText>
        </Stat>
        <Stat>
          <h1>70%</h1>
          <LText>of employees under 30 identified affording travel as the primary benefit of work</LText>
        </Stat>
      </StripeContainer>
      <Row>
        <DesktopContainer>
          <div>
            <SmallTitle>SHIFTING PERSPECTIVES</SmallTitle>
            <Divider height={20} />
            <h4>
              See how companies are grabbing headlines, experiencing less burnout, higher mental well-being & stronger
              company loyalty by paying their employees to vacation.
            </h4>
            <Divider height={20} />
            <LText>
              <StyledLink
                href="https://www.inc.com/business-insider/10-companies-that-pay-employees-extra-for-vacations.html"
                target="_blank"
              >
                Read more at <strong>Inc.</strong>
              </StyledLink>
            </LText>
          </div>
        </DesktopContainer>
        <DesktopContainer>
          <Circle>
            <SmallTitle style={{ marginLeft: 'auto', marginRight: 'auto' }}>THE PROBLEM</SmallTitle>
            <h2>95%</h2>
            <LText style={{ width: '90%', textAlign: 'center' }}>
              of HR Leaders rank employee turnover & burnout as a top workforce priority in 2020
            </LText>
          </Circle>
        </DesktopContainer>
      </Row>
      <Row>
        <CenteredDesktopContainer>
          <SmallTitle style={{ margin: 'auto' }}>THE SOLUTION</SmallTitle>
          <Divider height={20} />
          <h3>The Complete Travel Platform for Individuals and Organizations</h3>
          <Divider height={20} />
          <LText>
            Avoy combines its employee benefit platform that redeems reward points toward personal travel into it’s
            existing destination finding application, creating a community of shared interests.
          </LText>
        </CenteredDesktopContainer>
      </Row>
      <Row>
        <DesktopContainer>
          <div>
            <SmallTitle>PERKS</SmallTitle>
            <Divider height={20} />
            <h3>Reward through Travel</h3>
            <Divider height={20} />
            <LText>
              Employees receive points to redeem toward personal travel. The benefit that goes beyond PTO ensuring
              vacationing or staycationing occurs.
            </LText>
          </div>
        </DesktopContainer>
        <DesktopContainer style={{ justifyContent: 'center', display: 'flex' }}>
          <img
            alt=""
            style={{ width: '90%' }}
            src="https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/landing/business-landing-bank.png"
          />
        </DesktopContainer>
      </Row>
      <Row>
        <DesktopContainer>
          <img
            alt=""
            style={{ maxWidth: '100%', maxHeight: 180 }}
            src="https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/landing/first-interaction.png"
          />
          <Divider height={20} />
          <img
            alt=""
            style={{ maxWidth: '100%', marginLeft: 20, maxHeight: 202 }}
            src="https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/landing/second-interaction.png"
          />
        </DesktopContainer>
        <DesktopContainer>
          <div>
            <SmallTitle>COMMUNITY</SmallTitle>
            <Divider height={20} />
            <h3>Share travel experiences</h3>
            <Divider height={20} />
            <LText>
              Inspire shared stories about travel-related experiences to create a community within the organization.
            </LText>
          </div>
        </DesktopContainer>
      </Row>
      <Row>
        <DesktopContainer>
          <div>
            <SmallTitle>ENGAGEMENT ENSURED</SmallTitle>
            <Divider height={20} />
            <h3>Built for the employee first</h3>
            <Divider height={20} />
            <LText>
              Our consumer application allows your employees to build a custom profile that aligns your travel personas
              with the world's hidden gems.
            </LText>
          </div>
        </DesktopContainer>
        <DesktopContainer style={{ justifyContent: 'center', display: 'flex' }}>
          <img
            alt=""
            style={{
              maxWidth: '90%',
              maxHeight: 400,
              width: 'auto',
              height: 'auto',
              boxShadow: '0px 4px 4px rgba(0,0,0,0.15)',
              borderRadius: 15,
            }}
            src="https://avoy-assets.nyc3.cdn.digitaloceanspaces.com/landing/business-mobile-mock.png"
          />
        </DesktopContainer>
      </Row>
      <Divider height={80} />
      <AvoyFooter />
    </Wrapper>
  );
};
