import React, { Fragment } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Notification } from './Notification';
import { Instagram } from './Instagram';
import { YoutubeVideo } from './YoutubeVideo';
import { FlightDeal } from './FlightDeal';
import { AvoyFooter } from '../../components/footer/AvoyFooter';
import { getNotificationAction } from '../../api/api-actions';
import { useApi } from '../../api/use-api';

const Wrapper = styled.div`
  width: 100vw;
`;

const WidthManager = styled.div`
  max-width: calc(100vw - 80px);
  margin: auto;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    width: 520px;
  }
`;

const NakedNotificationScreen = props => {
  const history = useHistory();

  const { match } = props;
  const uuid = match.params.uuid || '';

  const [{ data, loading, error }] = useApi(getNotificationAction(uuid));

  if (loading) {
    return null;
  }

  if (error) {
    // show not found!
    history.push('/');
    return null;
  }

  function renderItem() {
    if (data.partner.name === 'Instagram') {
      return <Instagram data={data} />;
    } else if (data.partner.name === 'Youtube') {
      return <YoutubeVideo data={data} />;
    } else if (data.partner.name === 'Kiwi') {
      return <FlightDeal data={data} />;
    }

    return <Notification data={data} />;
  }

  return (
    <Wrapper>
      <WidthManager>{renderItem()}</WidthManager>
      <AvoyFooter />
    </Wrapper>
  );
};

export const NotificationScreen = withRouter(props => <NakedNotificationScreen {...props} />);
