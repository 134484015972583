import React, { useState, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { Spacer, LText, MText, SText, AccentButton } from '@lafabrica/core';
import { Logo } from './Logo';
import { TextField } from '../../components/TextField';
import { AppleLink } from '../AppleLink';
import { GoogleLink } from '../GoogleLink';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
`;

const CloseWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--white);

  @media only screen and (min-width: 769px) {
    right: 40px;
    top: 40px;
    width: 80px;
  }
`;

const Content = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
`;

const A = styled.a`
  color: var(--secondary);
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: var(--white);
  }
`;

const Links = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const TextMeTheApp = ({ visible, close }) => {
  const [phone, setPhone] = useState(null);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);

  if (!visible) {
    return null;
  }

  function sendText() {
    validateNumber();
  }

  function validateNumber() {
    if (phone && phone.length > 6 && phone.length < 13) {
      const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      if (phone.match(reg)) {
        setSent(true);
        return;
      }
    }
    setError('Invalid Phone Number');
  }

  return (
    <Wrapper>
      <CloseWrapper onClick={close}>
        <FontAwesomeIcon icon={faTimes} size={isMobile ? '1x' : '2x'} />
      </CloseWrapper>
      {sent ? (
        <Content>
          <div>
            <Logo noFlex />
          </div>
          <Spacer />
          <LText style={{ color: 'var(--white)' }}>
            <strong>We've sent a download link to {phone}!</strong>
          </LText>
          <Spacer times={0.5} />
          <MText style={{ color: 'var(--white)' }}>
            If you have any trouble receiving our message. You can download the Avoy app directly from the &nbsp;
            <A href="https://apps.apple.com/ca/app/avoy/id1497419517" target="_blank" noopener noreferrer>
              App Store
            </A>
            &nbsp;or{' '}
            <A href="https://play.google.com/store/apps/details?id=io.avoy.avoy" target="_blank" noopener noreferrer>
              Google Play
            </A>
            .
          </MText>
        </Content>
      ) : (
        <Fragment>
          <Content>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Logo big noFlex />
            </div>
            <Spacer />
            <LText style={{ color: 'var(--white)' }}>
              <strong>Get the Avoy app and discover hidden gems all around the world.</strong>
            </LText>
            <Spacer times={0.5} />
            <MText style={{ color: 'var(--white)' }}>
              Send a link to your phone, or download the Avoy app directly from the &nbsp;
              <A href="https://apps.apple.com/ca/app/avoy/id1497419517" target="_blank" noopener noreferrer>
                App Store
              </A>
              &nbsp;or{' '}
              <A href="https://play.google.com/store/apps/details?id=io.avoy.avoy" target="_blank" noopener noreferrer>
                Google Play
              </A>
              .
            </MText>
            <Spacer times={2} />
            <TextField dark label="Phone Number" name={'phone'} value={phone} setValue={setPhone} />
            {error && <SText style={{ color: 'var(--red)' }}>{error}</SText>}
            <Spacer />
            <AccentButton onClick={sendText}>Send Link</AccentButton>
            <Spacer times={3} />
          </Content>
          <Links>
            <AppleLink />
            <GoogleLink />
          </Links>
          <Spacer times={2} />
          <Content>
            <SText style={{ color: 'var(--white)' }}>
              By clicking "SEND LINK" you agree to &nbsp;
              <A
                href="https://www.termsfeed.com/terms-conditions/573f198132a2dceea013fa02a04a825e"
                target="_blank"
                noopener
                noreferrer
                style={{ color: 'var(--white)' }}
              >
                Terms and Conditions
              </A>{' '}
              and &nbsp;
              <A
                href="https://www.iubenda.com/privacy-policy/26986804"
                target="_blank"
                noopener
                noreferrer
                style={{ color: 'var(--white)' }}
              >
                Privacy Policy
              </A>
            </SText>
          </Content>
        </Fragment>
      )}
    </Wrapper>
  );
};
