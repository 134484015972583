import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faTrain, faSubway, faShip, faBus, faCompass } from '@fortawesome/free-solid-svg-icons';

import { FlagIcon } from './FlagIcon';
import { SmallHeader, Divider } from '@avoy/core';

import { secondsToHms } from '../utils/time';

const Row = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 2px 0px;
`;

const CountryTitle = styled(SmallHeader)`
  font-size: 16px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
`;

function getIcon(type) {
  switch (type.toUpperCase()) {
    case 'PLANE':
      return faPlane;
    case 'TRAIN':
      return faTrain;
    case 'SUBWAY':
      return faSubway;
    case 'CAR FERRY':
    case 'BOAT':
      return faShip;
    case 'BUS':
      return faBus;
    default:
      return faCompass;
  }
}

export const Hub = ({ hub, direct = false, showDetail = false }) => {
  const [expanded, setExpanded] = useState(false);

  function getTime() {
    if (hub.distance.length > 0) {
      const route = hub.distance[0];

      return (
        <FlexCol>
          {route.segments.map(segment => (
            <Row key={Object.keys(segment)[0]}>
              <FontAwesomeIcon icon={getIcon(Object.keys(segment)[0])} color="var(--light-black)" />
              <SmallHeader style={{ marginLeft: 8 }}>{secondsToHms(Object.values(segment)[0] * 60)}</SmallHeader>
            </Row>
          ))}
        </FlexCol>
      );
    }
    return null;
  }

  function getPrice() {
    const currency_symbol = '$';
    if (hub.distance.length > 0) {
      const route = hub.distance[0];

      if (route.prices && route.prices.length > 0) {
        const price = route.prices[0];
        return (
          <SmallHeader>
            ~{currency_symbol}
            {price.priceLow && price.priceHigh ? `${price.priceLow} - ${price.priceHigh}` : `${price.price}`}
          </SmallHeader>
        );
      }
    }
    return null;
  }

  // Currently disabled the ability to "dive" into more detail.
  return (
    <Fragment>
      <Row>
        <div style={{ textAlign: 'left', flex: 1 }}>
          <h5 style={{ margin: '5px 0' }}>
            {!direct && 'via'} {hub.relationship_with.title}
          </h5>
          <Row style={{ marginTop: '-10px' }}>
            <FlagIcon code={hub.relationship_with.iso_code_2} />
            <CountryTitle style={{ marginLeft: 8 }}>{hub.relationship_with.country_title}</CountryTitle>
          </Row>
        </div>
        <FlexCol>
          {getTime()}
          <Divider height={8} />
          {getPrice()}
        </FlexCol>
      </Row>
      <Divider height={10} />
      <Divider height={1} color={'var(--light-grey)'} solid />
      <Divider height={10} />
    </Fragment>
  );
};
