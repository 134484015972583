import axios from 'axios';

export const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function alertError(error) {
  console.log('API error', error, error && error.response && error.response.data);

  return Promise.reject(error);
}

axiosApi.interceptors.response.use(res => res, alertError);
