import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logo from '../../assets/square-logo-100-white.svg';
import blackLogo from '../../assets/square-logo-100-black.svg';
import purpleLogo from '../../assets/square-logo-100-purple.svg';

const HeaderLogo = styled(Link)`
  font-size: 20px;
  letter-spacing: 0.3px;
  text-decoration: none;
  ${props => !props.noFlex && `flex: 1;`}

  @media only screen and (min-width: 769px) {
    font-size: 30px;
  }
`;

const LogoSquare = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 6px;
  margin-right: 4px;

  @media only screen and (min-width: 769px) {
    margin-left: 10px;
    margin-top: 8px;
    margin-right: 8px;
    width: 25px;
    height: 25px;
  }
`;

const darkStyle = {
  color: 'var(--black)',
  transition: 'all 200ms ease-in',
  ':hover': { color: 'var(--black)' },
};

const whiteStyle = {
  color: 'var(--white)',
  transition: 'all 200ms ease-out',
  ':hover': { color: 'var(--white)' },
};

export const Logo = ({ dark, benefits = false, noFlex = false }) => (
  <Fragment>
    <Link to={benefits ? '/benefits' : '/'}>
      <LogoSquare src={dark ? purpleLogo : logo} />
    </Link>
    <HeaderLogo
      className={'logo'}
      to={benefits ? '/benefits' : '/'}
      style={dark ? darkStyle : whiteStyle}
      noFlex={noFlex}
    >
      {benefits ? 'Benefits' : 'Avoy'}
    </HeaderLogo>
  </Fragment>
);
