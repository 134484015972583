import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faMedium } from '@fortawesome/free-brands-svg-icons';

import { Divider } from '@avoy/core';
import { Logo } from './Logo';
import { AppleLink } from '../AppleLink';
import { GoogleLink } from '../GoogleLink';

const MobileMenuContainer = styled.div`
  width: 200px;
  padding: 20px;
  z-index: 999;
`;

const SidebarLink = styled.a`
  color: var(--light-black);
  text-decoration: none;

  &:hover {
    color: var(--accent);
  }
`;

const SidebarMainLink = styled(SidebarLink)`
  color: var(--black);
  font-weight: bold;
`;

const Socials = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MobileMenu = ({ setShowMenu = f => f }) => {
  const history = useHistory();

  return (
    <MobileMenuContainer>
      <Logo dark={true} />
      <Divider height={20} />
      <div style={{ padding: 15 }}>
        <AppleLink style={{ marginLeft: 10 }} />
        <GoogleLink />
        <Divider height={15} />
        <Divider height={1} color={'var(--light-grey)'} solid />
        <Divider height={15} />
        <SidebarMainLink
          onClick={() => {
            history.push('/mission');
            setShowMenu(false);
          }}
        >
          MISSION
        </SidebarMainLink>
        <Divider height={20} />
        <SidebarMainLink
          onClick={() => {
            history.push('/access');
            setShowMenu(false);
          }}
        >
          REQUEST ACCESS
        </SidebarMainLink>
        <Divider height={20} />
        <Divider height={1} color={'var(--light-grey)'} solid />
        <Divider height={20} />
        <SidebarLink
          onClick={() => {
            history.push('/benefits');
            setShowMenu(false);
          }}
        >
          Benefits
        </SidebarLink>
        <Divider height={20} />
        <SidebarLink
          onClick={() => {
            history.push('/partner');
            setShowMenu(false);
          }}
        >
          Partners
        </SidebarLink>
        <Divider height={20} />
        <SidebarLink
          onClick={() => {
            history.push('/press');
            setShowMenu(false);
          }}
        >
          Press
        </SidebarLink>
        <Divider height={20} />
        <SidebarLink
          style={{ color: 'var(--light-black)' }}
          href="https://www.iubenda.com/privacy-policy/26986804"
          target="_blank"
          noopener
          noreferrer
        >
          Privacy Policy
        </SidebarLink>
        <Divider height={20} />
        <SidebarLink
          style={{ color: 'var(--light-black)' }}
          href="https://www.termsfeed.com/terms-conditions/573f198132a2dceea013fa02a04a825e"
          target="_blank"
          noopener
          noreferrer
        >
          Terms & Conditions
        </SidebarLink>
        <Divider height={20} />
        <SidebarLink
          style={{ color: 'var(--light-black)' }}
          href="mailto:hi@avoy.io"
          target="_blank"
          noopener
          noreferrer
        >
          Contact
        </SidebarLink>
        <Divider height={20} />
        <Socials>
          <SidebarLink
            style={{ color: 'var(--light-black)', margin: 5 }}
            href="https://www.instagram.com/avoyhiddengems"
            target="_blank"
            noopener
            noreferrer
          >
            <FontAwesomeIcon icon={faInstagram} size={'lg'} />
          </SidebarLink>
          <SidebarLink
            style={{ color: 'var(--light-black)', margin: 5 }}
            href="https://www.linkedin.com/company/avoy"
            target="_blank"
            noopener
            noreferrer
          >
            <FontAwesomeIcon icon={faLinkedin} size={'lg'} />
          </SidebarLink>
          <SidebarLink
            style={{ color: 'var(--light-black)', margin: 5 }}
            href="https://www.medium.com/avoy/"
            target="_blank"
            noopener
            noreferrer
          >
            <FontAwesomeIcon icon={faMedium} size={'lg'} />
          </SidebarLink>
        </Socials>
      </div>
    </MobileMenuContainer>
  );
};
