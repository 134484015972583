import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 155px;
  height: 60px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 155px;
  height: 60px;
`;

export const GoogleLink = () => (
  <a href="https://play.google.com/store/apps/details?id=io.avoy.avoy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
    <Container>
      <Image src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
    </Container>
  </a>
);
