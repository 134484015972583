import {
  getPlaceAction,
  resetPasswordAction,
  updatePasswordAction,
  registerWaitlistAction,
  inviteWaitlistAction,
  conciergeAction,
} from './api-actions';
import { axiosApi } from './http';

export async function getPlace(slug) {
  const { data } = await axiosApi(getPlaceAction(slug));

  return data;
}

export async function resetPassword(email) {
  const { data } = await axiosApi(resetPasswordAction(email));

  return data;
}

export async function registerWaitlist(values) {
  const { data } = await axiosApi(registerWaitlistAction(values));

  return data;
}

export async function inviteWaitlist(values) {
  const { data } = await axiosApi(inviteWaitlistAction(values));

  return data;
}

export async function updatePassword(uuid, pw) {
  const { data } = await axiosApi(updatePasswordAction(uuid, pw));

  return data;
}

export async function conciergeRegister(values) {
  const { data } = await axiosApi(conciergeAction(values));

  return data;
}
